.fm-performance-hours {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.fm-performance-hours-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 9px;
}

.fm-performance-hours-options .fm-input-container {
  margin: 0;
  flex: 1;
  margin: 0 6px;
}

.fm-performance-hours-options-go {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  color: #222222;
  background: #F8F7F9;
  border-radius: 5px;
  padding: 10px 15px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0 6px;

  &:hover {
    background: #F4F3F4;
  }
}

.fm-performance-hours-event-row-description {
	text-transform: capitalize;
	flex: 1;
	line-height: 20px;
	padding: 0 12px;
	font-size: 15px;
}

.fm-performance-hours-event-row-type {
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 2px 5px;
	border-radius: 3px;
	display: inline-block;
	cursor: pointer;
	background: #000;
	width: 100px;
	text-align: center;
}

.fm-performance-hours-row {
	padding: 0 15px;
}

.fm-performance-hours-row-name {
	font-size: 16px;
	font-weight: 600;
	line-height: 25px;
}

.fm-performance-hours-event-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;
}

.fm-performance-hours-events {
	margin: 10px 0 20px 0;
}

.fm-performance-hours-event-row-time {
	font-size: 15px;
	color: $light-text-color;
}
