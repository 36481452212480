.fm-manual-list-search .fm-input-container {
  margin: 15px;
}

.fm-manual-list-items {
  padding: 0;
  margin-bottom: 50px;
}

.fm-manual-list-item {
  padding: 15px;
  display: block;
  margin: 10px 0 10px 0;
}
