.fm-task-priority-item {
  margin: 20px 15px 35px 15px;
  display: block;
}

.fm-task-priority-filters {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 13px 13px 0 13px;
}

.fm-task-priority-filters .fm-input-select + .fm-input-select {
  margin-left: 15px;
}

.fm-task-priority-info {
  margin: 15px;
}

.fm-task-priority-info-text {
  font-size: 14px;
  line-height: 20px;
  margin: 7px 0;
}

.fm-task-priority-item-title {
  display: block;
  height: 28px;
  background: #eb3b5a;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  line-height: 28px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 5px;
  margin-bottom: 20px;
}

.fm-task-priority-item-title-high {
  background: #eb3b5a;
  animation: highBannerAnimation 4s infinite linear;
}

.fm-task-priority-item-title-medium {
  background: #e67e22;
}

.fm-task-priority-item-map {
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 25px;
}

.fm-task-priority-item-header {
  display: flex;
  align-items: center;
}

.fm-task-priority-item-status {
  background: red;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.fm-task-priority-item-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-task-priority-item-created {
  flex: 1;
  text-align: right;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

.fm-task-priority-item-rego {
  font-size: 14px;
  color: #fff;
  background: #000;
  font-weight: 600;
  width: 55px;
  text-align: center;
  padding: 2px 0;
  border-radius: 3px 0 0 3px;
  flex: 0 0 55px;
  line-height: 20px;
}

.fm-task-priority-item-details {
  flex: 1;
  text-align: left;
  font-size: 15px;
  margin-top: 9px;
  padding: 1px;
  line-height: 20px;
}

.fm-task-priority-item-notes {
  flex: 1;
  text-align: left;
  font-size: 15px;
  margin-top: 5px;
  padding: 1px;
  line-height: 20px;
  margin-bottom: 8px;
}

.fm-task-priority-item-user {
  flex: 1;
  text-align: left;
  margin-top: 5px;
  padding: 1px;
  line-height: 20px;
  font-size: 14px;
  color: #999;
}

.fm-task-priority-item-buttons {
  display: flex;
  margin: 15px 0;
}

.fm-task-priority-item-button {
  border: 0;
  cursor: pointer;
  display: block;
  background: #f8f7f9;
  padding: 10px 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 4px 0 10px;
  height: 40px;
  transition: all .2s ease;
  text-align: center;
  flex: 1 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.fm-task-priority-item-button + .fm-task-priority-item-button {
  margin-left: 15px;
}

@media (prefers-color-scheme: dark) {
  .fm-task-priority-item-button {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
}
