.fm-subscription-new-form {
  padding: 0 15px 30px;
}

.fm-subscription-new-input {
  flex: 1 1;
  margin: 20px 0 25px;
}

.fm-subscription-new-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #FF206E;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 13px 0;
  cursor: pointer;
}
