.fm-incident-events {
  padding-bottom: 50px;
}

/* Stats */

.fm-incident-events-stats {
  background: $light-background-color;
  border-radius: 3px;
  margin: 15px 15px 0 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.fm-incident-events-stats-types {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}

.fm-incident-events-stats-open {
  margin: 10px 12px;
  padding: 10px 30px 10px 15px;
  display: flex;
  align-items: center;
  border-right: 1px solid $border-color;
}

.fm-incident-events-stats-open-text {
  line-height: 24px;
  font-size: 15px;
}

.fm-incident-events-stats-value {
  font-weight: 600;
  margin-left: 5px;
}

.fm-incident-events-stats-value {
  font-weight: 600;
  margin-left: 2px;
}

.fm-incident-events-stats-type-dot {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  margin: 5px 15px 5px 0;
}

.fm-incident-events-stats-type {
  display: flex;
  align-items: center;
}

/* Filters */

.fm-incident-events-filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 15px 0 15px;
  gap: 15px;
}

.fm-incident-events-filter-group {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}

/* Search */

.fm-incident-events-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}

.fm-incident-events-search-bar .fm-input-container {
  flex: 1;
  margin: 0;
}

/* Results */

.fm-incident-events-results {
  margin: 0 15px;
}

.fm-incident-events-result {
  padding: 25px 10px;
  border-bottom: 1px solid $border-color;
}

.fm-incident-events-result-bottom {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 24px;
  overflow: hidden;
}

.fm-incident-events-result-dot {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  margin: 5px 10px 5px 0;
}

.fm-incident-events-result-item {
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  margin-right: 5px;
  color: $light-text-color;
}

.fm-incident-events-result-title {
  font-weight: 600;
  line-height: 22px;
}

.fm-incident-events-results-empty {
  color: $light-text-color;
  font-size: 14px;
  text-align: center;
  line-height: 200px;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-incident-events-stats {
    background-color: $light-background-color-dark;
  }
  .fm-incident-events-stats-open {
    border-right: 1px solid $border-color-dark;
  }
  .fm-incident-events-result {
    border-bottom: 1px solid $border-color-dark;
  }
}

/* ------ Mobile Support ------ */

@media (max-width: $breakpoint-md) {
  .fm-incident-events-stats {
    flex-direction: column;
  }

  .fm-incident-events-stats-open {
    border-right: none;
    padding: 10px 0;
  }

  .fm-incident-events-stats-types {
    width: 100%;
  }

  .fm-incident-events-stats-type {
    margin-bottom: 20px;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-incident-events-stats-types {
    flex-wrap: wrap;
  }

  .fm-incident-events-stats-type {
    width: 50%;
    justify-content: center;
  }

  .fm-incident-events-filter {
    flex-direction: column;
  }

  .fm-incident-events-filter-group {
    width: 100%;
  }
}
