.fm-users {
  padding-bottom: 50px;
}

.fm-users-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}

.fm-users-search-bar .fm-input-container {
  flex: 1;
  margin: 0;
}

.fm-users-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid $border-color;
}

.fm-users-result-status {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
}

.fm-users-results {
  padding: 0 25px 15px 25px;
}

.fm-users-results-empty {
  color: #999;
  font-size: 14px;
  text-align: center;
  line-height: 200px;
}

.fm-users-result-name {
  font-size: 16px;
  flex: 1;
}

.fm-users-result-phone {
  text-align: left;
  flex: 1;
  font-size: 14px;
  color: $light-text-color;
}

.fm-users-result-email {
  text-align: left;
  flex: 1;
  font-size: 14px;
  color: $light-text-color;
}

.fm-users-result-disabled, .fm-users-result-deleted, .fm-users-result-suspended {
  color: $light-text-color;
}

@media (max-width: $breakpoint-md) {
  .fm-users-result-phone {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-users-result {
    border-bottom-color: $border-color-dark;
  }
}
