@media print {
  .fm-platform, .fm-platform-content, .fm-platform-switch {
    height: auto;
  }
  .fm-tab-bar, .fm-navigation-back, .fm-navigation-action {
    display: none;
  }
  .fm-navigation-bar {
    position: relative;
  }
}
