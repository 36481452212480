.fm-incident-new-content {
  padding: 0 15px 30px 15px;
}

.fm-incident-new-input-row {
  display: flex;
  flex-direction: row;
}

.fm-incident-new-input, .fm-incident-new-input-row  {
  margin: 20px 0 25px 0;
  flex: 1;
}

.fm-incident-new-input-row .fm-incident-new-input {
  margin: 0;
}

.fm-incident-new-input-row .fm-incident-new-input:nth-child(2) {
  margin-left: 20px;
}

.fm-incident-new-textarea {
  min-height: 150px;
  padding: 10px 15px;
}

.fm-incident-new-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 13px 0;
  cursor: pointer;
}

.fm-incident-new-map {
  height: 250px;
  margin-bottom: 25px;
  border-radius: 3px;
  overflow: hidden;
}

@media (max-width: $breakpoint-sm) {
  .fm-incident-new-input-row .fm-incident-new-input:nth-child(2) {
    margin-left: 0;
  }

  .fm-incident-new-input-row {
    display: block;
  }

  .fm-incident-new-input, .fm-incident-new-input-row  {
    margin: 0;
  }

  .fm-incident-new-input-row .fm-incident-new-input {
    margin: 20px 0 25px 0;
  }

  .fm-incident-new-input + .fm-incident-new-map {
    margin: 20px 0 25px 0;
  }
}
