.fm-task-assign {
  padding: 20px 15px;
}

.fm-task-assign-button {
  border: 0;
  font-size: 16px;
  color: $text-color;
  background: $light-background-color;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 26px 0 13px 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .fm-task-assign-button {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
}
