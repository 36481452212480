.fm-warehouses {
  margin: 15px;
}

.fm-warehouses-item {
  margin-bottom: 30px;
}

.fm-warehouses-item-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.fm-warehouses-item-address {
  color: $light-text-color;
  font-size: 14px;
  line-height: 24px;
}

.fm-warehouses-item-notes {
  font-size: 15px;
  white-space: pre-wrap;
  margin: 5px 0;
  line-height: 20px;
}

.fm-warehouses-item-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fm-warehouses-item-button {
  border: 0;
  cursor: pointer;
  display: block;
  background: #f8f7f9;
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 10px 10px 10px 0;
  height: 40px;
  transition: all .2s ease;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  color: $text-color;
}
