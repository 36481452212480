.fm-vehicle-identifiers-row {
  margin: 20px 15px;
}

.fm-vehicle-identifiers-row-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-identifiers-row-value {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 2px;
  cursor: copy;
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
}

.fm-vehicle-identifiers-row-label {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}
