.fm-hub-view-header {
  height: 400px;
  display: flex;
  flex-direction: row;
}

.fm-hub-view-photo {
  flex: 1;
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
}

.fm-hub-view-map {
  flex: 1;
}

.fm-hub-view-container {
  padding: 15px 15px 50px 15px;
}

.fm-hub-view-capacity {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  line-height: 30px;
}

.fm-hub-view-tags {
  border-radius: 3px;
  padding: 5px 12px;
  display: inline-block;
  margin: 0 0 15px 15px;
  display: flex;
}

.fm-hub-view-type {
  margin: 4px 1px 4px 8px;
  height: 22px;
}

.fm-hub-view-address {
  font-size: 22px;
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 10px;
}

.fm-hub-view-description {
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0 20px 0;
}

.fm-hub-view-target {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.fm-hub-view-targets {
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0 15px 0;
}

@media (max-width: $breakpoint-sm) {
  .fm-hub-view-header {
    flex-direction: column;
    height: 500px;
  }
}
