.fm-hub-current-items {
  display: flex;
  padding: 15px;
  flex-direction: row;
  flex-wrap: wrap;
}

.fm-hub-current-item {
  border-radius: 3px;
  background: #f8f7f9;
  margin: 0 15px 15px 0;
  flex: 0 0 300px;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
}

.fm-hub-current-overlay {
  padding: 12px;
  display: flex;
  height: 270px;
  width: 100%;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0) 100%);
  justify-content: space-between;
}

.fm-hub-current-status {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 2px solid #fff;
}

.fm-hub-current-target {
  height: 42px;
  margin: 0;
  display: flex;
}

.fm-hub-current-target-label {
  font-size: 30px;
  font-weight: 800;
  line-height: 42px;
  color: #fff;
}

.fm-hub-current-target-icon {
  height: 30px;
  margin: 6px 0 6px 10px;
}

.fm-hub-current-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}

@media (max-width: $breakpoint-md) {
  .fm-hub-current-item {
    flex: 0 0 100%;
    flex: 0 0 calc(50% - 7.5px);
  }
  .fm-hub-current-item:nth-child(2n) {
    margin: 0 0 15px;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-hub-current-item {
    flex: 0 0 100%;
  }
  .fm-hub-current-item {
    margin: 0 0 15px;
  }
}
