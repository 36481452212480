.fm-report-pending-select {
  margin: 15px 15px 25px 15px;
}

.fm-report-pending-item {
	margin: 15px 15px 30px 15px;
	display: block;
}

.fm-report-pending-item-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.fm-report-pending-item-title {
	font-size: 17px;
	font-weight: 600;
	line-height: 24px;
}

.fm-report-pending-item-details {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
}

.fm-report-pending-item-tag {
  height: 24px;
  padding: 0;
  width: 105px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}

.fm-report-pending-item-description {
	flex: 1;
	margin: 0 10px;
	font-size: 14px;
	line-height: 22px;
	color: $light-text-color;
	margin-left: 10px;
	overflow: hidden;
	height: 22px;
	word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fm-report-pending-item-user {
	font-size: 14px;
	line-height: 22px;
}

.fm-report-pending-item-time {
	color: $light-text-color;
	font-size: 14px;
}

.fm-report-pending-empty {
  font-size: 14px;
  text-align: center;
  height: 300px;
  line-height: 300px;
  color: $light-text-color;
}
