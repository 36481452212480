.fm-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-height: 100vh;
}

.fm-login-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

.fm-login-logo {
  background: url('https://storage.googleapis.com/flamingo-static/images/admin/wordmark-black.svg') 50% 50% no-repeat;
  height: 40px;
  background-size: 120px;
  margin: 10px 0;
}

.fm-login-title {
  font-size: 18px;
  color: $light-text-color;
  text-align: center;
}

.fm-login-form {
  margin: 60px 0;
}

.fm-login .fm-input-label, .fm-login .fm-input {
  text-align: center;
}

.fm-login .fm-button {
  margin-top: 50px;
}
