.fm-hub-map {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-hub-map-holder {
  flex: 1;
}
