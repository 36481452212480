.fm-analytics-location {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fm-analytics-location-content {
  display: flex;
  flex: 1;
}

.fm-analytics-location-controls {
  width: 500px;
  border-left: 1px solid $border-color;
  padding: 15px;
}

.fm-analytics-location-input {
  margin: 20px 0 25px;
  flex: 1 1;
}

.fm-analytics-location-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #ff206e;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  width: 100%;
}

.fm-analytics-location-empty {
  font-size: 14px;
  color: $light-text-color;
  margin-top: 5px;
  line-height: 20px;
}

.fm-analytics-location-data {
  margin-bottom: 30px;
}

.fm-analytics-location-data-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.fm-analytics-location-data-label {
  line-height: 20px;
  font-weight: 600;
}
