.fm-more {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}

.fm-more-navigation {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.fm-more-link {
  line-height: 20px;
  padding: 17px 10px 17px 35px;
  font-size: 15px;

  .fa {
    margin-right: 20px;
    color: $light-text-color;
    width: 20px;
    text-align: center;
  }

  &:hover .fa {
    color: $text-color;
  }
}

.fm-more-profile {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.fm-more-profile-avatar {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: #ffbad2;
}

.fm-more-profile-details {
  padding-left: 15px;
  flex: 1;
}

.fm-more-profile-name {
  font-size: 16px;
  line-height: 22px;
}

.fm-more-profile-region {
  color: #999;
  font-size: 14px;
  line-height: 20px;
}

.fm-more-extra {
  border-top: 1px solid $border-color;
  background: $light-background-color;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 20px;
}

@media (prefers-color-scheme: dark) {
  .fm-more-extra {
    border-top-color: $border-color-dark;
    background: $background-color-dark;
  }
  .fm-more-link:hover .fa {
    color: #fff;
  }
}
