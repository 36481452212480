.fm-input {
  border: 0;
  font-size: 16px;
  color: $text-color;
  background: $light-background-color;
  border-radius: 3px;
  padding: 10px 12px;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
}

.fm-input-container {
  margin: 30px 0;
}

.fm-input-label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.fm-input-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACJSURBVEhL7ZC9CYBADEbP2iEEp7JxAPdwBZex02EcQ/0iBCzC/ZlTkDx4RYrLC+cMwzB+QQ1bRRtYwSAbPJQdoRe6bIbS41x32MEg9NULlJakStEBRqMRT44yFF+htDRkdpTJiT+OMilxtSgTE1ePMr54sSgjxYtHmXv8tShD8Qn212QYxnc4dwKskJKEHrOFUQAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 98% 50%;
  background-color: $light-background-color;
  border-radius: 5px;
}

.fm-input-textarea {
  max-width: 100%;
  resize: none;
}

.fm-input-hint {
  font-size: 12px;
  color: $light-text-color;
  line-height: 16px;
  margin: 8px 0 0 0;
}

/* CHECKBOX */

.fm-input-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  border: 1px solid $border-color;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: #fff;
  margin: 12px 0;
}

.fm-input-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.fm-input-checkbox-checkmark {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 100%;
}

.fm-input-checkbox-container:hover input ~ .fm-input-checkbox-checkmark {
  background-color: $border-color;
}

/* When the checkbox is checked, add a blue background */
.fm-input-checkbox-container input:checked ~ .fm-input-checkbox-checkmark {
  background-color: $primary;
}

.fm-input-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 3px;
  background: $light-background-color;
  outline: none;
}

.fm-input-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
}

.fm-input-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .fm-input, .fm-input-select, .fm-input-slider {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
}
