/* ------ Items ------ */

.fm-dashboard-items {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
}

.fm-dashboard-item {
  display: flex;
  align-items: center;
  height: 80px;
  margin: 15px 0 0 0;
  justify-content: flex-start;
  background: $light-background-color;
  border-radius: 3px;
  overflow: hidden;
  flex-basis: calc(50% - 15px / 2);
}

.fm-dashboard-item:nth-child(2n) {
  margin: 15px 0 0 15px;
}

.fm-dashboard-item-icon {
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: $text-color;
  color: #fff;
}

.fm-dashboard-item-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.fm-dashboard-item-count {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 5px;
}

.fm-dashboard-item-text {
  margin-left: 25px;
}

@media (max-width: $breakpoint-md) {
  .fm-dashboard-item {
    flex-basis: 100%;
  }

  .fm-dashboard-item:nth-child(2n) {
    margin: 15px 0 0 0;
  }
}

/* ------ Regions ------ */

.fm-dashboard-regions {
  padding: 10px 0;
}

.fm-dashboard-region {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
}

.fm-dashboard-region-name {
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
}

.fm-dashboard-region-type {
  color: $light-text-color;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 3px;
  letter-spacing: 0.2px;
}

.fm-dashboard-region-counts {
  background: $light-background-color;
  border-radius: 3px 3px 0 0;
}

.fm-dashboard-region-inuse {
  color: #00A8FF;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.fm-dashboard-region-total {
  color: #4CD137;
  font-weight: 800;
  line-height: 20px;
  font-size: 16px;
}

.fm-dashboard-region-prow {
  color: #2f3640;
  font-weight: 800;
  line-height: 20px;
  font-size: 16px;
}

.fm-dashboard-region-slash {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color: $border-color;
  padding: 0 10px;
}

.fm-dashboard-region-counts-inner {
  display: flex;
  padding: 7px 15px;
}

.fm-dashboard-region-counts-bar {
  height: 2px;
  background: $border-color;
}

/* ------ Events ------ */

.fm-dashboard-events {
  padding: 15px;
}

.fm-dashboard-events-title {
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
}

.fm-dashboard-event {
  margin: 20px 0;
}

.fm-dashboard-event-header {
  display: flex;
  align-items: center;
}

.fm-dashboard-event-name {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.fm-dashboard-event-tags {
  height: 20px;
  margin: 5px 0;
}

.fm-dashboard-event-tag {
  font-size: 12px;
  font-weight: 600;
  color: $light-text-color;
  background: $light-background-color;
  padding: 0 6px;
  border-radius: 3px;
  line-height: 20px;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 5px;
}

.fm-dashboard-event-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.fm-dashboard-event-dates {
  font-size: 13px;
  color: $light-text-color;
  line-height: 20px;
  margin-left: 5px;
}

@media (max-width: $breakpoint-sm) {

}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-dashboard-region-counts, .fm-dashboard-item, .fm-dashboard-event-tag {
    background-color: $light-background-color-dark;
  }
  .fm-dashboard-region-slash {
    color: $border-color-dark;
  }
  .fm-dashboard-region-counts-bar {
    background: $border-color-dark;
  }
  .fm-dashboard-event-tag {
    color: $light-text-color;
  }
}
