.fm-instruction-groups-items {
  padding: 0;
  margin-bottom: 50px;
}

.fm-instruction-groups-item {
  padding: 15px;
  display: block;
  margin: 10px 0 10px 0;
}
