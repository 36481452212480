.nest-view {

}

.fm-nest-view-photo-container {
  background: #ccc;
  display: flex;
  justify-content: center;
  height: 250px;
}

.fm-nest-view-photo {
  height: 250px;
  background-size: cover;
  background-position: 50%;
  max-width: 400px;
  width: 100%;
}

.fm-nest-view-content {
  padding: 22px 15px;
}

.fm-nest-view-address {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin: 14px 0 20px 0;
}

.fm-nest-view-tags {
  display: flex;
  align-items: center;
}

.fm-nest-view-spacer {
  flex: 1;
}

.fm-nest-view-status {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
}

.fm-nest-view-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #000;
  padding: 2px 7px;
  border-radius: 3px 0 0 3px;
  line-height: 20px;
}

.fm-nest-view-capacity {
  font-size: 13px;
  font-weight: 600;
  color: #666;
  background: #eee;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-nest-view-map {
  height: 300px;
  margin-top: 10px;
}
