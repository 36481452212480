.fm-banner {
  display: block;
  width: 100%;
  height: 50px;
  flex: 0 0 51px;
  border-bottom: 1px solid #E8E7EA;
  background: #eb3b5a;
  z-index: 9999;
}

@keyframes highBannerAnimation {
  0%   { background: #eb3b5a; }
  40%   { background: #eb3b5a; }
  50%  { background: #b2122f; }
  60% { background: #eb3b5a; }
  100% { background: #eb3b5a; }
}

.fm-banner-high {
  background: #eb3b5a;
  animation: highBannerAnimation 4s infinite linear;
}

.fm-banner-medium {
  background: #e67e22;
}

.fm-banner + .fm-platform-content .fm-navigation-bar {
  top: 51px;
}

.fm-banner + .fm-platform-content .fm-navigation-loading {
  position: fixed;
  top: 111px;
}

.fm-banner-link {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fm-banner-title {
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  line-height: 20px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  height: 20px;
  width: 100%;
  padding: 0 5px;
  white-space: nowrap;
}

.fm-banner-notes {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .fm-banner {
    border-bottom-color: $border-color-dark;
  }
}
