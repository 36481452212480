.fm-toast {
  position: fixed;
  left: 0;
  width: 100%;
  text-align: center;
  animation: fm-show-toast 4s ease;
  animation-fill-mode: forwards;
}

.fm-toast-inner {
  display: inline-block;
  padding: 10px 20px;
  font-size: 15px;
  background-color: $primary;
  border-radius: 5px;
  color: #fff;
}

@keyframes fm-show-toast {
  0% {
    bottom: -80px;
  }

  10% {
    bottom: 50px;
  }

  90% {
    bottom: 50px;
  }

  100% {
    bottom: -80px;
  }
}
