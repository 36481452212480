.fm-report-trip-content {
  display: flex;
  padding: 7px;
  flex-wrap: wrap;
}

.fm-report-trip-category {
  padding: 7px;
  flex: 50%;
  cursor: pointer;
}

.fm-report-trip-category-card {
  background: $light-background-color;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.fm-report-trip-category-icon {
  text-align: center;
  font-size: 30px;
  padding: 10px 0;
  color: $border-color-dark;
}

.fm-report-trip-category-title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  padding: 10px 0;
}

.fm-report-trip-category-description {
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  color: $border-color-dark;
  margin-bottom: 5px;
}

.fm-report-trip-parking {
  background: $light-background-color;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 14px 14px 28px 14px;
  height: 400px;
}

.fm-report-trip-parking-img {
  max-height: 100%;
  max-width: 100%;
}

@media (max-width: $breakpoint-sm) {
  .fm-report-trip-content {
    flex-direction: column;
  }
  .fm-report-trip-category {
    flex: 1;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-report-trip-category-card, .fm-report-trip-parking {
    background-color: $light-background-color-dark;
  }
  .fm-report-trip-category-icon {
    color: $border-color;
  }
  .fm-report-trip-category-description {
    color: $light-text-color;
  }
}
