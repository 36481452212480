.fm-radio-group-label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.fm-radio-group-inputs .fm-radio {
  display: inline-block;
  margin-right: 50px;
}

.fm-radio-group-inputs .fm-radio:last-child {
  margin-right: 0px;
}
