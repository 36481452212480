.fm-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  animation: fm-show-popup 0.2s ease;
  animation-fill-mode: forwards;
}

.fm-popup-active {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
}

.fm-popup-box {
  min-height: 200px;
  background: #fff;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  max-height: 80%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.fm-popup-title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px 25px 20px;
}

.fm-popup-description {
  padding: 0 20px 15px 20px;
  text-align: center;
  font-size: 14px;
  color: $light-text-color;
  line-height: 22px;
}

.fm-popup .fm-loading {
  margin-top: 30px;
}

@keyframes fm-show-popup {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}
