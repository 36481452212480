.fm-task-active-item {
  margin: 30px 15px;
  display: block;
}

.fm-task-active-filters {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 13px 13px 0 13px;
}

.fm-task-active-filters .fm-input-select + .fm-input-select {
  margin-left: 15px;
}

.fm-task-active-item-header {
  display: flex;
  align-items: center;
}

.fm-task-active-item-status {
  background: red;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.fm-task-active-item-types {
  display: flex;
}

.fm-task-active-item-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 15px;
  border-radius: 0;
  line-height: 20px;
}

.fm-task-active-item-type:last-child {
  border-radius: 0 3px 3px 0;
}

.fm-task-active-item-created {
  flex: 1;
  text-align: right;
  padding: 0 8px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

.fm-task-active-item-rego {
  font-size: 14px;
  color: #fff;
  background: #000;
  font-weight: 600;
  width: 55px;
  text-align: center;
  padding: 2px 0;
  border-radius: 3px 0 0 3px;
  flex: 0 0 55px;
  line-height: 20px;
}

.fm-task-active-item-details {
  flex: 1;
  text-align: left;
  font-size: 15px;
  margin-top: 9px;
  padding: 1px;
  line-height: 20px;
}

.fm-task-active-top {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 15px 15px 10px 15px;
}

.fm-task-active-top-card {
  border-radius: 4px;
  flex: 1;
  height: 55px;
  display: flex;
  align-items: center;
}

.fm-task-active-top-card-ticket {
  background: #EB3B5A;
}

.fm-task-active-top-card-offline {
  background: #e67e22;
}

.fm-task-active-top-highlight {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding: 0 15px;
  min-width: 55px;
  text-align: center;
}

.fm-task-active-top-label {
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  padding-right: 10px;
}

.fm-task-active-cards {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  height: 85px;
}

.fm-task-active-card {
  background: $light-background-color;
  border-radius: 3px;
  margin: 15px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
}

.fm-task-active-card-highlight {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  line-height: 35px;
  background: #e8421a;
  padding: 10px 15px;
  min-width: 55px;
  text-align: center;
}

.fm-task-active-card-description {
  font-weight: 500;
  color: $text-color;
  font-size: 14px;
  line-height: 18px;
  padding: 0 15px;
  max-width: 120px;
}

.fm-task-active-card + .fm-task-active-card {
  margin: 15px 15px 15px 0;
}

.fm-task-active-card:last-child {
  margin-right: 15px;
}

.fm-task-active-trend {
  background: $light-background-color;
  border-radius: 3px;
  display: flex;
  margin: 13px 13px 0 13px;
}

.fm-task-active-trend-item {
  line-height: 18px;
  flex: 1;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-task-active-trend-item .fa {
  font-size: 16px;
  line-height: 18px;
  color: $light-text-color;
}

.fm-task-active-trend-text {
  color: $light-text-color;
  font-weight: 600;
  font-size: 14px;
}

@media (prefers-color-scheme: dark) {
  .fm-task-active-trend, .fm-task-active-card {
    background: $light-background-color-dark;
  }
  .fm-task-active-card-description {
    color: $text-color-dark;
  }
}
