.fm-beam-content {
  padding: 0 15px 30px 15px;
}

.fm-beam-input {
  margin: 20px 0 25px 0;
}

.fm-beam-scanner {
  margin: 20px 0;
  border-radius: 3px;
  overflow: hidden;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-beam-content .fm-task-new-button {
  margin-bottom: 25px;
}

.fm-beam-check-info {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-top: 8px;
}

.fm-beam-check-error {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-top: 8px;
  color: $light-text-color;
}
