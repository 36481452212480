.fm-page-heading {
  height: 250px;
  background-size: cover;
  background-position: 50%;
}

.fm-page-heading-overlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.fm-page-heading-content {
  width: 100%;
  height: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.fm-page-heading-title {
  color: #fff;
  font-size: 50px;
  padding: 0;
}

@media(max-width: $breakpoint-lg) {
  .fm-page-heading-title {
    padding: 0 20px;
  }
}
