.fm-hires {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fm-hires-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fm-hires-empty {
  color: $light-text-color;
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 150px;
}

.fm-hires-main {
  display: flex;
  flex: 1;
}

.fm-hires-main-photo {
  flex: 2;
  margin: 15px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: $light-background-color;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: right;
}

.fm-hires-main-photo-title {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  background: $light-background-color;
  display: inline-block;
  border-radius: 10px 0 0 0;
  font-weight: 600;
}

.fm-hires-main-photos {
  flex: 1;
  margin: 15px 5px;
  display: flex;
  flex-direction: column;
}

.fm-hires-main-photos .fm-hires-main-photo {
  margin: 0;
}

.fm-hires-main-photo + .fm-hires-main-photo {
  margin-top: 15px;
}

.fm-hires-statuses {
  flex: 2;
  margin: 15px;
  overflow-y: scroll;
}

.fm-hires-status {
  margin-bottom: 15px;
}

.fm-hires-status-top {
  display: flex;
  align-items: center;
}

.fm-hires-status-badge-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #639;
  padding: 2px 7px;
  border-radius: 3px 0 0 3px;
  line-height: 20px;
}

.fm-hires-status-badge-source {
  font-size: 13px;
  font-weight: 600;
  color: #666;
  background: #eee;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-hires-status-date {
  flex: 1 1;
  text-align: right;
  padding: 0 0 0 10px;
  font-size: 14px;
  color: $light-text-color;
  line-height: 20px;
}

.fm-hires-status-details {
  color: $light-text-color;
  font-size: 14px;
  padding: 5px 0;
  margin-top: 4px;
  line-height: 20px;
}

.fm-hires-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 133px;
  padding: 5px 15px 20px 15px;
}

.fm-hires-details-previous-item {
  font-size: 13px;
  font-weight: 600;
  color: #666;
  background: #eee;
  padding: 2px 7px;
  border-radius: 3px;
  line-height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.fm-hires-details-name {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  font-weight: 600;
}

.fm-hires-details-time {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
}

.fm-hires-details-previous {
  margin-top: 15px;
}

.fm-hires-details-action {
  flex: 1;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  line-height: 35px;
  font-size: 15px;
  cursor: pointer;
  height: 40px;
  padding: 0 20px;
  margin-left: 10px;
}

.fm-hires-details-action-warning {
  background: #f0932b;
}

.fm-hires-details-action-fine {
  background: #e74c3c;
}

.fm-hires-details-action-dismiss {
  background: #2ecc71;
}

/* ------ Media Queries ------ */

@media (max-width: $breakpoint-md) {
  .fm-hires-main {
    flex-direction: column;
  }
  .fm-hires-main-photo {
    flex: none;
    height: 300px;
  }
  .fm-hires-main-photos {
    flex-direction: row;
    margin: 5px 15px 20px 15px;
  }
  .fm-hires-main-photos .fm-hires-main-photo {
    flex: 1;
  }
  .fm-hires-details {
    flex-direction: column;
    flex: none;
  }
  .fm-hires-details-items {
    width: 100%;
    margin-bottom: 30px;
  }
  .fm-hires-details-actions {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }
  .fm-hires-details-action-fine {
    margin-left: 0;
  }
  .fm-hires-statuses {
    overflow-y: auto;
  }
  .fm-hires-main-photo + .fm-hires-main-photo {
    margin-top: 0;
    margin-left: 20px;
  }
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-hires-empty, .fm-hires-main-photo-title {
    background-color: $light-background-color-dark;
  }
}
