@import './components/nest-edit/nest-edit.scss';
@import './components/nest-map/nest-map.scss';
@import './components/nest-top/nest-top.scss';
@import './components/nest-view/nest-view.scss';

.fm-nests {
  padding-bottom: 50px;
}

.fm-nests-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fm-nests-search-bar .fm-input-container {
  flex: 1;
}

.fm-nests-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid $border-color;
}

.fm-nests-result-address {
  font-weight: 500;
  font-size: 16px;
  padding: 5px 8px;
}

.fm-nests-result-status {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
}

.fm-nests-results {
  padding-bottom: 15px;
}

.fm-nests-results-empty {
  color: #999;
  font-size: 14px;
  text-align: center;
  line-height: 200px;
}

/* MAP */

.fm-nests-map {
  height: 500px;
}

/* NEST */

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-nest-map-bar, .fm-nest-map-filters {
    background-color: $background-color-dark;
    border-top-color: $border-color-dark;
  }
  .fm-nest-map-bar-filter {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
  .fm-nest-map-result {
    border-bottom-color: $border-color-dark;
  }
}
