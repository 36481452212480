.fm-ticket-new {
  margin: 15px 13px 20px 13px;
}

/* ------ Slider ------ */

.fm-ticket-new-slider {
  height: 40px;
  background-color: #f8f7f9;
  border-radius: 3px;
  padding: 2px;
  position: relative;
  overflow: hidden;
}

.fm-ticket-new-slider-background {
  background: pink;
  top: 2px;
  bottom: 2px;
  transition: ease all 0.3s;
  position: absolute;
  border-radius: 2px;
}

.fm-ticket-new-slider-maintenance .fm-ticket-new-slider-background {
  left: 2px;
  right: 50%;
  background-color: #eb4d4b;
}

.fm-ticket-new-slider-note .fm-ticket-new-slider-background {
  left: 50%;
  right: 2px;
  background-color: #f0932b;
}

.fm-ticket-new-slider-options {
  z-index: 9;
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-ticket-new-slider-option {
  flex: 1;
  height: 36px;
  cursor: pointer;
  background: none;
  font-weight: 600;
  color: #000;
  transition: ease all 0.2s;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.fm-ticket-new-slider-maintenance .fm-ticket-new-slider-option-maintenance, .fm-ticket-new-slider-note .fm-ticket-new-slider-option-note {
  color: #fff;
}

/* ------ Input ------ */

.fm-ticket-new-input .fm-input-container {
  margin: 20px 0 10px 0;
}

.fm-ticket-new-input-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.fm-ticket-new-input-checkbox .fm-input-label {
  margin-bottom: 0;
}

/* ------ Button ------ */

.fm-ticket-new-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $secondary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
}
