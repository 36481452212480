.fm-table {
  width: 100%;
  margin: 40px 0;
  border-top: 1px solid $border-color;
}

.fm-table td {
  padding: 20px 0;
  border-bottom: 1px solid $border-color;
  font-size: 14px;
}
