.fm-maintenance-solution-container {
  padding: 20px 15px 50px 15px;
}

.fm-maintenance-solution-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 22px;
}

.fm-maintenance-solution-subtitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 22px;
}

.fm-maintenance-solution-text {
  font-size: 14px;
  margin-bottom: 25px;
  line-height: 20px;
  white-space: pre-line;
}

.fm-maintenance-solution-img {
  max-width: 100%;
  margin: 10px 0;
}
