.fm-radio {
  margin: 5px 0;
}

.fm-radio-label {
  display: flex;
  cursor: pointer;
}

.fm-radio-input {
  display: none;
}

.fm-radio-text {
  display: block;
  font-size: 15px;
  line-height: 19px;
}

.fm-radio-circle {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  background-color: #fff;
  border: 1px solid $secondary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-radio-circle-inner {
  height: 12px;
  width: 12px;
  display: block;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.fm-radio:hover .fm-radio-circle-inner {
  background-color: #DDDDDD;
}

.fm-radio-input:checked ~ .fm-radio-circle .fm-radio-circle-inner {
  background-color: $primary;
}

.fm-radio-disabled:hover {
  .fm-radio-label {
    cursor: not-allfmed;
  }

  .fm-radio-circle-inner {
    background-color: #FFFFFF;
  }
}
