
/* ------ Content ------ */

.fm-user-section-header {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-user-section-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
}

.fm-user-section-button {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  border-radius: 3px;
  margin: 1px 15px 1px 0;
  background: #f8f7f9;
  cursor: pointer;
}

/* ------ Notes ------ */

.fm-user-notes {
  padding: 10px 15px 25px 15px;
}

.fm-user-notes-text {
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
}

/* ------ Status ------ */

.fm-user-status {
  padding: 17px 0 3px 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.fm-user-status-item {
  width: 16.666666%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 17px;
}

.fm-user-status-item-icon {
  border-radius: 50%;
  background: $light-background-color;
  padding: 12px;
  width: 44px;
  height: 44px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.fm-user-status-item-icon-pink {
  color: $primary;
}

.fm-user-status-item-text {
  font-size: 14px;
  line-height: 22px;
  margin: 7px 0 5px 0;
  text-align: center;
  overflow-wrap: break-word;
  width: 100%;
}

@media (max-width: $breakpoint-xl) {
  .fm-user-status-item {
    width: 33.33333%;
  }
}

@media (max-width: $breakpoint-md) {
  .fm-user-status-item {
    width: 50%;
    padding: 0 10px;
  }
}

/* ------ Notes ------ */

.fm-user-notes .fm-input-container {
  margin: 0;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-user-status-item-icon, .fm-user-section-button {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
  .fm-trip-duration-line {
    background-color: $border-color-dark;
  }
}
