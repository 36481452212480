.fm-loading-line {
  width: 100%;
  height: 2px;
  overflow: hidden;
  margin: 0;
}

.fm-loading-line-inner {
  background-color: $primary;
  margin-top: 0;
  margin-left: -250px;
  animation-name: bounce_loading;
  -o-animation-name: bounce_loading;
  -ms-animation-name: bounce_loading;
  -webkit-animation-name: bounce_loading;
  -moz-animation-name: bounce_loading;
  animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  width: 200px;
  height: 2px;
}

@keyframes bounce_loading {
	0%{
		margin-left: -100vw;
	}

	100%{
		margin-left: 100vw;
	}
}

@-o-keyframes bounce_loading {
	0%{
		margin-left: -100vw;
	}

	100%{
		margin-left: 100vw;
	}
}

@-ms-keyframes bounce_loading {
	0%{
		margin-left: -100vw;
	}

	100%{
		margin-left: 100vw;
	}
}

@-webkit-keyframes bounce_loading {
	0%{
		margin-left: -100vw;
	}

	100%{
		margin-left: 100vw;
	}
}

@-moz-keyframes bounce_loading {
	0%{
		margin-left: -100vw;
	}

	100%{
		margin-left: 100vw;
	}
}
