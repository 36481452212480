.fm-analytics-safety-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #ff206e;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  width: 150px;
  margin-left: 15px;
}

.fm-analytics-safety-form {
  display: flex;
  margin: 15px;
}

table.fm-analytics-safety-table {
  width: 100%;
  padding: 0 15px 15px 15px;
}

td.fm-analytics-safety-table-label {
  padding: 10px 0;
}

.fm-analytics-safety-table-label-capitalize {
  text-transform: capitalize;
}

td.fm-analytics-safety-table-value {
  padding: 10px 0;
  text-align: right;
}

.fm-analytics-safety-table td {
  border-bottom: 1px solid #eee;
  transition: ease all 0.1s;
}

.fm-analytics-safety-table tr:hover td {
  background: $light-background-color;
}

.fm-analytics-safety-empty {
  color: #999;
  text-align: center;
  font-size: 14px;
  margin-top: 100px;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-analytics-safety-table td {
    border-bottom-color: $border-color-dark;
  }
  .fm-analytics-safety-table tr:hover td {
    background: $light-background-color-dark;
  }
}
