.fm-fallen-map {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-fallen-map-holder {
  flex: 1 1;
  position: relative;
}
