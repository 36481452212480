/* ----- Generic Stats ----- */

.fm-statistics-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.fm-statistics-subtitle {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 25px;
  margin: 10px 0;
  font-weight: 600;
}

.fm-statistics-region {
  margin: 15px 15px 50px 15px;
}

/* ----- Sums ----- */

.fm-statistics-region-sums-item {
  background: $light-background-color;
  margin: 15px 0;
  border-radius: 3px;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  justify-content: space-between;
}

.fm-statistics-region-sums-item-type {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}

.fm-statistics-region-sums-item-values {
  display: flex;
}

.fm-statistics-region-sums-item-label {
  font-weight: 800;
  line-height: 20px;
  font-size: 16px;
}

.fm-statistics-region-sums-item-label-inuse {
  color: #00a8ff;
}

.fm-statistics-region-sums-item-label-hireable {
  color: #4cd137;
}

.fm-statistics-region-sums-item-label-onstreet {
  color: #2f3640;
}

.fm-statistics-region-sums-item-label-cap {
  color: $light-text-color;
}

.fm-statistics-region-sums-item-slash {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color: $border-color;
  padding: 0 10px;
}

.fm-statistics-region-sums-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.fm-statistics-region-sums-battery {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: $light-text-color;
}

.fm-statistics-region-sums-battery .fa {
  margin: 0 3px;
}

.fm-statistics-region-sums-note {
  font-size: 12px;
  font-weight: 600;
  text-align: right;
}

.fm-statistics-region-sums-note-inuse {
  color: #00a8ff;
}

.fm-statistics-region-sums-note-hireable {
  color: #4cd137;
}

.fm-statistics-region-sums-note-onstreet {
  color: #2f3640;
}

.fm-statistics-region-sums-note-cap {
  color: $light-text-color;
}

.fm-statistics-region-sums-item-totals {
  background-color: $light-background-color-dark;
}

.fm-statistics-region-sums-item-totals .fm-statistics-region-sums-item-type {
  color: $text-color-dark;
}

.fm-statistics-region-sums-item-totals .fm-statistics-region-sums-item-slash {
  color: $border-color-dark;
}

.fm-statistics-region-sums-item-totals .fm-statistics-region-sums-item-label-onstreet {
  color: #fff;
}

/* ----- Statues ----- */

.fm-statistics-region-statuses {
  margin: 30px 0;
}

.fm-statistics-region-statuses-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

.fm-statistics-region-statuses-item {
  border-radius: 3px;
  height: 110px;
  padding: 10px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 120px;
}

.fm-statistics-region-statuses-item-total {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  line-height: 40px;
  margin-bottom: 2px;
}

.fm-statistics-region-statuses-item-label {
  font-weight: 500;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

/* ----- Trips ----- */

.fm-statistics-region-trips {
  margin: 30px 0;
}

.fm-statistics-region-trips-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-statistics-region-trips-text {
  font-size: 15px;
  line-height: 20px;
}

/* ----- Max Available ----- */

.fm-statistics-region-hourly {
  margin: 30px 0;
}

.fm-statistics-region-daily {
  margin: 30px 0;
}

.fm-statistics-region-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: calc(100% + 30px);
  overflow-x: scroll;
  gap: 10px;
  margin: 0 -15px;
  padding: 0 15px;
}

.fm-statistics-region-chart-column {
  flex: 1 1;
  overflow: hidden;
  min-width: 55px;
}

.fm-statistics-region-chart-title {
  transform: rotate(45deg);
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
  color: $light-text-color;
}

.fm-statistics-region-chart-value {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  font-weight: 400;
  flex: 1;
}

.fm-statistics-region-chart-values {
  display: flex;
}

.fm-statistics-region-chart-holder {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.fm-statistics-region-chart-bar {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  text-transform: capitalize;
  line-height: 30px;
  font-weight: 600;
  background: $primary;
}

/* ----- Dark Mode ----- */

@media (prefers-color-scheme: dark) {
  .fm-statistics-region-sums-item {
    background: $light-background-color-dark;
  }
  .fm-statistics-region-sums-note-onstreet, .fm-statistics-region-sums-item-label-onstreet {
    color: $border-color;
  }
  .fm-statistics-region-sums-item-slash {
    color: $border-color-dark;
  }
}

@media (max-width: $breakpoint-md) {
  .fm-statistics-region-statuses-item {
    flex: 0 0 100px;
    height: 90px;
  }
  .fm-statistics-region-statuses-item-label {
    font-size: 10px;
    font-weight: 800;
    letter-spacing: -0.25px;
  }
}
