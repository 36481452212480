.fm-document-templates-item {
  margin: 20px 20px 25px 20px;
  display: block;
}

.fm-document-templates-item-title {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}

.fm-document-templates-item-period {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin: 4px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}
