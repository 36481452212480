.fm-vehicle-burnouts {
  margin-bottom: 50px;
}

.fm-vehicle-burnouts-text {
  margin: 15px 20px 10px 20px;
  line-height: 20px;
  font-size: 14px;
}

.fm-vehicle-burnouts-warning {
  margin: 20px;
  line-height: 20px;
  font-size: 14px;
  padding: 6px 10px;
  background: #EB3B5A;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
}

.fm-vehicle-burnouts-item {
  margin: 30px 20px;
  display: block;
}

.fm-vehicle-burnouts-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-burnouts-item-tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fm-vehicle-burnouts-item-tag-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #639;
  padding: 2px 7px;
  border-radius: 3px 0 0 3px;
  line-height: 20px;
  text-transform: uppercase;
}

.fm-vehicle-burnouts-item-tag-flag {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #111;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-vehicle-burnouts-item-date {
  flex: 1 1;
  text-align: right;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.fm-vehicle-burnouts-item-text {
  flex: 1;
  text-align: left;
  font-size: 14px;
  margin-top: 9px;
  padding: 1px;
  line-height: 20px;
}

.fm-vehicle-burnouts-item-text-notes {
  color: #999;
}

.fm-vehicle-burnouts-item-occurrences {
  flex: 1;
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  padding: 1px;
  line-height: 20px;
  font-weight: bold;
}

.fm-vehicle-burnouts-item-occurrences-sig {
  color: #EB3B5A;
}
