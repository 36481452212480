.fm-task-route-item {
  padding: 15px;
  display: block;
}

.fm-task-route-item:first-child {
  background: #222222;
  padding: 30px 15px;
  margin-bottom: 10px;
}

.fm-task-route-item-header {
  display: flex;
  align-items: center;
}

.fm-task-route-item-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-task-route-item-rego {
  font-size: 14px;
  color: #fff;
  background: #000;
  font-weight: 600;
  width: 55px;
  text-align: center;
  padding: 2px 0;
  border-radius: 3px 0 0 3px;
  flex: 0 0 55px;
  line-height: 20px;
}

.fm-task-route-item:first-child .fm-task-route-item-rego {
  font-size: 22px;
  line-height: 30px;
  width: 80px;
  flex: 0 0 80px;
  font-weight: 800;
}

.fm-task-route-item:first-child .fm-task-route-item-type {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}

.fm-task-route-item-actions {
  display: flex;
  margin-top: 20px;
}

.fm-task-route-item-actions-button {
  border: 0;
  cursor: pointer;
  display: block;
  background: #333;
  color: #fff;
  padding: 10px 0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 44px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  text-align: center;
  flex: 1 1;
}

.fm-task-route-item-actions-button + .fm-task-route-item-actions-button {
  margin-left: 10px;
}
