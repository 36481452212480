.fm-inspection-schedule-item {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-inspection-schedule-item-link {
  display: flex;
  align-items: center;
  flex: 1;
}

.fm-inspection-schedule-item-vehicle {
  display: flex;
  align-items: center;
}

.fm-inspection-schedule-item-rego {
  font-size: 14px;
  color: #fff;
  background: #000;
  font-weight: 600;
  width: 55px;
  text-align: center;
  padding: 2px 0;
  border-radius: 3px 0 0 3px;
  flex: 0 0 55px;
  line-height: 20px;
}

.fm-inspection-schedule-item-status {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-inspection-schedule-item-inspection {
  flex: 1 1;
  text-align: right;
  padding: 0;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  margin: 0 15px 0 0;
}

.fm-inspection-schedule-item-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fm-inspection-schedule-item-button {
  font-size: 14px;
  color: #666;
  background: #eee;
  font-weight: 400;
  text-align: center;
  padding: 2px 8px 2px 10px;
  border-radius: 3px;
  line-height: 22px;
}

.fm-inspection-schedule-item-button .fa {
  color: #999;
  margin-left: 6px;
  line-height: 20px;
  font-size: 13px;
}

@media (max-width: $breakpoint-sm) {
  .fm-inspection-schedule-item-link {
    flex-direction: column;
    align-items: flex-start;
  }

  .fm-inspection-schedule-item-inspection {
    margin: 10px 0 0 0;
  }
}

/* GUIDES */

.fm-inspection-schedule-guides {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.fm-inspection-schedule-guide {
  background-color: $light-background-color;
  border-radius: 3px;
  margin: 15px 15px 5px 15px;
  height: 175px;
  padding: 10px 15px 10px 10px;
  width: 135px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  flex: 0 0 135px;
}

.fm-inspection-schedule-guide + .fm-inspection-schedule-guide {
  margin: 15px 15px 5px 0;
}

.fm-inspection-schedule-guide-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.fm-inspection-schedule-guide-icon {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-inspection-schedule-guide {
    background-color: $light-background-color-dark;
  }
}
