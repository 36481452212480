.fm-manual-view-container {
  margin: 15px 15px 50px 15px;
}

.fm-manual-view-container {
  p {
    font-size: 15px;
    line-height: 22px;
    display: block;
    margin: 15px 0 10px 0;
  }
  img {
    max-height: 350px;
    max-width: 100%;
    margin: 5px 0;
  }
  ul {

  }
  li {
    font-size: 15px;
    line-height: 22px;
    margin-left: 25px;
    margin-bottom: 2px;
  }
  a {
    color: #ff206e;
    text-decoration: underline;
  }
  h1, h2, h3 {
    display: block;
    margin: 25px 0 10px;
  }
  blockquote {
    border-left: 3px solid $border-color;
  }
  blockquote p {
    margin: 0;
    padding: 5px 0 10px 10px;
    font-size: 14px;
    color: $light-text-color;
  }
  blockquote + blockquote p {
    padding: 0 0 5px 10px;
  }
  code {
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin: 15px 0 10px 0;
    background: $light-background-color;
    padding: 10px;
    border-radius: 3px;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-manual-view-container {
    blockquote {
      border-left: 3px solid $border-color-dark;
    }
    code {
      background: $light-background-color-dark;
    }
  }
}
