.fm-vehicle-sounds-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.fm-vehicle-sounds-button {
  border: 0;
  cursor: pointer;
  display: block;
  background: #f8f7f9;
  padding: 10px 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 4px 0 10px;
  height: 40px;
  transition: all .2s ease;
  text-align: center;
  flex: 1 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.fm-vehicle-sounds-history {
  margin: 20px 0;
}

.fm-vehicle-sounds-history-empty {
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0;
  text-align: center;
}
