.fm-report-parking-stats-content {
  display: flex;
  padding: 7px 7px 50px 7px;
  flex-direction: column;
}

.fm-report-parking-stats-card {
  flex: 1;
  cursor: pointer;
  background: $light-background-color;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 7px;
  height: 100px;
  align-items: center;
}

.fm-report-parking-stats-card-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
}

.fm-report-parking-stats-card-donut {
  --w: 84px;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  margin: 5px;
  font-size: 18px;
  font-weight: 600;
  font-family: sans-serif;
  line-height: 84px;
}

.fm-report-parking-stats-card-donut:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background: conic-gradient(var(--c) calc(var(--p)*1%), $border-color 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.fm-report-parking-stats-card-filler {
  height: 84px;
  width: 84px;
  border: 10px solid $border-color;
  border-radius: 50%;
  margin: 5px;
}

.fm-report-parking-stats-card-title {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  padding: 10px 0;
}

.fm-report-parking-stats-card-description {
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  color: $border-color-dark;
  margin-bottom: 14px;
}

@media (prefers-color-scheme: dark) {
  .fm-report-parking-stats-card {
    background-color: $light-background-color-dark;
  }
  .fm-report-parking-stats-card-description {
    color: $light-text-color;
  }
  .fm-report-parking-stats-card-donut:before {
    background: conic-gradient(var(--c) calc(var(--p)*1%), $border-color-dark 0);
  }
  .fm-report-parking-stats-card-filler {
    border: 10px solid $border-color;
  }
}

@media (max-width: $breakpoint-md) {
  .fm-report-parking-stats-desktop {
    display: none;
  }
}
