.fm-task-alerts {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-task-alerts-map {
  flex: 1 1;
  position: relative;
}

.fm-task-alerts-error {
  color: $light-text-color;
  margin: 100px 20px 50px 20px;
  text-align: center;
}
