.fm-report-parking-statistics {
  margin-bottom: 50px;
}

.fm-report-parking-statistics-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #ff206e;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  width: 150px;
  margin-left: 15px;
}

.fm-report-parking-statistics-form {
  display: flex;
  margin: 15px;
}

.fm-report-parking-statistics-empty {
  color: #999;
  text-align: center;
  font-size: 14px;
  margin-top: 100px;
}

.fm-report-parking-statistics-region-bar {
  height: 30px;
  background: $light-background-color;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  width: 200px;
  flex: 0 0 200px;
}

.fm-report-parking-statistics-region-bar-inner {
  height: 30px;
}

.fm-report-parking-statistics-region-bar-value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  font-size: 14px;
}

.fm-report-parking-statistics-region-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin: 15px 0;
}

.fm-report-parking-statistics-region {
  margin: 15px 15px 25px 15px;
}

.fm-report-parking-statistics-region-name {
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
}

.fm-report-parking-statistics-region-item-label {
  line-height: 20px;
  font-size: 15px;
}

.fm-report-parking-statistics-region-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}

.fm-report-parking-statistics-region-type-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.fm-report-parking-statistics-region-type-description {
  font-size: 14px;
  line-height: 20px;
  flex: 1;
  color: $light-text-color;
}

.fm-report-parking-statistics-region-types {
  margin: 20px 0 30px 0;
}

.fm-report-parking-statistics-region-type-total {
  font-size: 14px;
  line-height: 20px;
}

@media (prefers-color-scheme: dark) {
  .fm-report-parking-statistics-region-bar {
    background-color: $light-background-color-dark;
  }
}

@media (max-width: $breakpoint-md) {
  .fm-report-parking-statistics-region-type-description {
    display: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-report-parking-statistics-region-bar {
    width: 100px;
    flex: 0 0 100px;
    height: 40px;
  }
  .fm-report-parking-statistics-region-bar-inner {
    height: 40px;
  }
  .fm-report-parking-statistics-region-bar-value {
    line-height: 40px;
  }
  .fm-report-parking-statistics-region-item-label {
    line-height: 20px;
    font-size: 14px;
  }
}
