.fm-scan {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fm-scan-reader {
  width: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}

.fm-scan-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 15px;
  height: 50px;
  flex: 0 0 50px;
}

.fm-scan-bar-status {
  border-radius: 7px;
  height: 14px;
  width: 14px;
  flex: 0 0 14px;
}

.fm-scan-bar-label {
  font-size: 16px;
  line-height: 20px;
  flex: 1;
}
