.fm-maintenance-guide {
  padding-bottom: 30px;
}

.fm-maintenance-guide-video-container {
  padding: 15px;
}

.fm-maintenance-guide-video {
  min-height: 300px;
  height: 300px;
  height: 60vh;
}

.fm-maintenance-guide-instruction {
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 20px;
  padding: 15px 15px 30px 15px;
}
