.fm-firmware-view-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.fm-firmware-view-list {
  padding: 15px;
}

.fm-firmware-view-section-header {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-firmware-view-section-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
}

.fm-firmware-view-history-time {
  color: #9c9b9d;
  font-size: 14px;
  margin-right: 15px;
}

.fm-firmware-view-history {
  padding: 15px;
}

.fm-firmware-view-history-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.fm-firmware-view-history-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.fm-firmware-view-history-status {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 0;
  border-radius: 3px;
  text-align: center;
  width: 95px;
}

.fm-firmware-view-history-left {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}


@media (max-width: $breakpoint-md) {
  .fm-firmware-view-history-left {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
  }
  .fm-firmware-view-history-time {
    margin-top: 5px;
  }
}
