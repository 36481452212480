.fm-analytics-operations-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #ff206e;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  width: 150px;
  margin-left: 15px;
}

.fm-analytics-operations-form {
  display: flex;
  margin: 15px;
}

table.fm-analytics-operations-table {
  width: 100%;
  padding: 0 15px 15px 15px;
}

td.fm-analytics-operations-table-label {
  padding: 10px 0;
}

td.fm-analytics-operations-table-value {
  padding: 10px 0;
  text-align: right;
}

.fm-analytics-operations-table td {
  border-bottom: 1px solid #eee;
}

.fm-analytics-operations-empty {
  color: #999;
  text-align: center;
  font-size: 14px;
  margin-top: 100px;
}
