.fm-support-activity-options {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 22px 9px;
}

.fm-support-activity-options .fm-input-container {
  flex: 1 1;
  margin: 0 6px;
}

.fm-support-activity-item-date {
  color: #999;
  flex: 1 1;
  font-size: 14px;
  line-height: 20px;
  padding: 0 10px;
  text-align: right;
}

.fm-support-activity-item-event {
  background-color: grey;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 0 15px;
  padding: 2px 5px;
  text-transform: uppercase;
}

.fm-support-activity-item {
  align-items: center;
  display: flex;
  margin: 10px 0 25px;
}

.fm-support-activity-options-go {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  color: #222222;
  background: $light-background-color;
  border-radius: 5px;
  padding: 10px 15px;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0 6px;
}


/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-support-activity-options-go {
    background: $light-background-color-dark;
    color: $text-color-dark;
  }
}
