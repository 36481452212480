.fm-forecast-region {
  margin: 15px 15px 50px;
}

.fm-forecast-region-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.fm-forecast-region-statistics {
  align-items: flex-start;
  background: #f8f7f9;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 12px;
  gap: 20px;
}

.fm-forecast-region-statistics-item {
  flex: 1;
}

.fm-forecast-region-statistics-item-value-positive {
  color: #2ecc71;
}

.fm-forecast-region-statistics-item-value-negative {
  color: #e74c3c;
}

.fm-forecast-region-statistics-item-title {
  font-size: 14px;
  text-align: center;
  line-height: 18px;
}

.fm-forecast-region-statistics-item-value {
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
}

.fm-forecast-region-chart {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 35px 0;
  max-width: 100%;
  overflow-x: scroll;
}

.fm-forecast-region-chart-item {
  flex: 1 1;
  overflow: hidden;
  padding: 0 2px;
}

.fm-forecast-region-chart-item-bars {
  align-items: flex-end;
  display: flex;
  flex: 1 1;
  height: 150px;
  justify-content: flex-end;
  padding: 0 5px;
  position: relative;
  width: 100%;
}

.fm-forecast-region-chart-item-value {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0;
  text-align: center;
}

.fm-forecast-region-chart-item-hour {
  color: #999;
  font-size: 12px;
  padding: 10px 0;
  text-align: center;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fm-forecast-region-chart-item-bar-fortnight {
  background: #000;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 50%;
}

.fm-forecast-region-chart-item-bar-forecast {
  bottom: 0;
  position: absolute;
  right: 0;
  width: 50%;
  background: #fdb1cb;
}

.fm-forecast-region-chart-item-bar-current {
  bottom: 0;
  position: absolute;
  right: 0;
  width: 50%;
  background: #ff206e;
}

@media (max-width: $breakpoint-md) {
  .fm-forecast-region-chart {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .fm-forecast-region-chart-item {
    min-width: 35px;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-forecast-region-statistics-item-value {
    font-size: 22px;
  }
  .fm-forecast-region-statistics-item-title {
    font-size: 13px;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-forecast-region-statistics {
      background: #1e1e1e;
  }
}
