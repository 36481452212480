.fm-subscription-plan-content {
  margin-top: 12px;
  margin-bottom: 50px;
}

.fm-subscription-plan-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px 20px 15px;
}

.fm-subscription-plan-row-key {
  font-size: 15px;
}

.fm-subscription-plan-row-value {
  font-size: 15px;
  color: $light-text-color;
}

.fm-subscription-plan-row-terms {
  padding: 0 0 10px 0;
  line-height: 20px;
  font-size: 14px;
  color: $light-text-color;
}

.fm-subscription-plan-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
  margin: 10px 0 12px 0;
  white-space: nowrap;
}

.fm-subscription-plan-plans {
  margin: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fm-subscription-plan-join-input {
  background: $light-background-color;
  border: 0;
  border-radius: 3px;
  color: $text-color;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-size: 16px;
  line-height: 21px;
  padding: 10px 12px;
  transition: all .1s ease;
  flex: 1;
}

.fm-subscription-plan-join {
  display: flex;
  padding: 0 15px;
  gap: 15px;
}

.fm-subscription-plan-join-button {
  background: $text-color;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  transition: all .1s ease;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  padding: 10px 12px;
}

.fm-subscription-list-item-user {
  margin: 20px 15px;
  display: block;
}

.fm-subscription-list-item-user-name {
  font-size: 15px;
}
