.fm-report-view-tag {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.fm-report-view-details-status {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 2px 10px;
	border-radius: 3px;
  line-height: 22px;
	display: inline-block;
}

.fm-report-view-details-action {
  color: #fff;
  background: #222;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 0 3px 3px 0;
  display: inline-block;
  line-height: 22px;
}

.fm-report-view-details-action + .fm-report-view-details-status {
	border-radius: 3px 0 0 3px;
}

.fm-report-view-content {
	display: flex;
	flex-direction: row;
	padding: 15px 15px 50px 15px;
}

.fm-report-view-details {
	flex: 2;
	padding-right: 15px;
}

.fm-report-view-user, .fm-report-view-trip {
	margin: 20px 0;
}

.fm-report-view-user-name {
	font-size: 17px;
	font-weight: 600;
	line-height: 25px;
}

.fm-report-view-user-email, .fm-report-view-user-phone, .fm-report-view-trip-times, .fm-report-view-trip-zone-name {
	font-size: 15px;
  line-height: 20px;
  margin: 5px 0;
	display: block;
}

.fm-report-view-user-plan {
  color: #fff;
  background: #222;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 3px;
  display: inline-block;
  line-height: 22px;
  margin: 5px 0 0 0;
}

.fm-report-view-trip-zone-name {
  font-weight: 600;
}

.fm-report-view-trip-zone-message {
	font-size: 14px;
  line-height: 20px;
  margin: 5px 0;
  color: $light-text-color;
  font-style: italic;
	display: block;
}

.fm-report-view-user-notes {
  font-size: 14px;
  margin: 15px 0;
  color: $light-text-color;
  font-style: italic;
  line-height: 20px;
  white-space: pre-wrap;
}

.fm-report-view-pending-notes {
  font-size: 14px;
  line-height: 20px;
  margin: 0 1px 12px 2px;
  color: $light-text-color;
  font-style: italic;
  display: block;
}

.fm-report-view-info, .fm-report-view-action {
  background: $light-background-color;
  border-radius: 3px;
  padding: 12px;
	margin: 25px 0;
}

.fm-report-view-created-at {
	font-size: 14px;
	font-style: italic;
	color: $light-text-color;
	line-height: 16px;
}

.fm-report-view-created-by {
	font-size: 14px;
	font-style: italic;
	color: $light-text-color;
	line-height: 16px;
  margin-bottom: 7px;
}

.fm-report-view-activity-item-content {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 8px;
  display: block;
}

.fm-report-view-activity-item-details {
  font-size: 14px;
  font-style: italic;
  color: #9C9B9D;
  line-height: 16px;
}

.fm-report-view-activity-item {
  background: #F8F7F9;
  border-radius: 3px;
  padding: 12px;
  margin: 10px 0;
}

.fm-report-view-notes {
	line-height: 20px;
	margin-bottom: 10px;
}

.fm-report-view-actions {
  margin: 25px 0;
}

.fm-report-view-actions .fm-input-select {
  margin-bottom: 13px;
}

.fm-report-view-actions-notes {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.fm-report-view-actions-notes-button {
  border: 0;
  font-size: 16px;
  color: #fff;
  background: #222;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px;
  transition: all .1s ease;
  display: block;
  line-height: 21px;
  margin: 0 0 0 12px;
  cursor: pointer;
  flex: 0 0 41px;
  width: 41px;
}

.fm-report-view-action-button {
  border: 0;
  font-size: 16px;
  color: #fff;
  background: $text-color;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  transition: all .1s ease;
  display: block;
  width: 100%;
  font-family: -apple-system,BlinkMacSystemFont,San Francisco,Lato,Segoe UI,Helvetica Neue,sans-serif;
  line-height: 21px;
  margin: 13px 0;
  cursor: pointer;
}

.fm-report-view-action-button:hover, .fm-report-view-actions-notes-button:hover {
  background: #000;
}

.fm-report-view-action-warning {
  font-size: 14px;
  color: #fff;
  background: #e8421a;
  border-radius: 3px;
  padding: 10px 12px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
}

.fm-report-view-recommendation-header {
  display: flex;
  flex-direction: row;
  margin: 25px 0 15px 0;
  justify-content: space-between;
}

.fm-report-view-recommendation-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}

.fm-report-view-recommendation-info {
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  color: $light-text-color;
  background: none;
  font-size: 16px;
  padding: 0 0 0 5px;
}

.fm-report-view-recommendation-text {
	font-size: 14px;
	line-height: 20px;
  margin-top: 12px;
}

.fm-report-view-recommendation-text-highlight {
  background: #e8421a;
  padding: 0 6px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  margin: 0 2px;
  line-height: 20px;
  display: inline-block;
}

.fm-report-view-history-title {
	font-size: 17px;
	font-weight: 600;
	line-height: 25px;
  margin: 25px 0 15px 0;
}

.fm-report-view-history-empty {
  font-size: 14px;
	color: $light-text-color;
}

.fm-report-view-media {
	flex: 3;
}

.fm-report-view-images {
	display: flex;
	flex-direction: row;
  height: 400px;
	background: $light-background-color;
	border-radius: 3px;
  overflow: hidden;
	padding: 0 10px;
	margin-bottom: 15px;
}

.fm-report-view-images-parking {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
  position: relative;
}

.fm-report-view-images-missing {
  font-size: 14px;
}

.fm-report-view-images-report {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
  position: relative;
}

.fm-report-view-images-report-img {
	max-width: 100%;
	max-height: 100%;
  cursor: pointer;
}

.fm-report-view-images-parking-img {
	max-width: 100%;
	max-height: 100%;
  cursor: pointer;
}

.fm-report-view-images-tag {
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.fm-report-view-images-tag-text {
  background: $light-background-color;
  text-transform: uppercase;
  color: $text-color;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 3px 3px 0 0;
  line-height: 22px;
  display: inline-block;
}

.fm-report-view-images-tag-selected .fm-report-view-images-tag-text {
  background: $primary;
  color: #fff;
}

.fm-report-view-images-note {
  font-size: 14px;
  margin: 15px 0;
  color: $light-text-color;
  font-style: italic;
  line-height: 20px;
  white-space: pre-wrap;
}

.fm-report-view-map {
  height: 250px;
  border-radius: 3px;
  overflow: hidden;
}

.fm-report-view-history-item-action {
	color: #fff;
	background: #222;
	font-size: 12px;
	font-weight: 600;
	padding: 2px 10px;
	border-radius: 0 3px 3px 0;
	display: inline-block;
	line-height: 20px;
}

.fm-report-view-history-item-status {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 3px 0 0 3px;
  line-height: 20px;
  display: inline-block;
  }

.fm-report-view-history-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-report-view-history-item-text {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}

.fm-report-view-history-item-date {
  font-size: 14px;
  line-height: 20px;
  color: $light-text-color;
}

.fm-report-view-history-item {
  display: block;
  margin: 20px 0 25px;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-report-view-images, .fm-report-view-info, .fm-report-view-action, .fm-report-view-activity-item {
    background-color: $border-color-dark;
  }
  .fm-report-view-created-by, .fm-report-view-created-at {
    color: $text-color-dark;
  }
}

/* ------ Sizing ------ */

@media (max-width: $breakpoint-lg) {
  .fm-report-view-details, .fm-report-view-media {
    flex: 1;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-report-view-content {
    flex-direction: column;
  }
  .fm-report-view-details {
    padding-right: 0;
  }
  .fm-report-view-media {
    margin-top: 15px;
  }
  .fm-report-view-images {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
}
