.fm-insights {

}

fm-insights-row-light {
  color: $light-text-color;
}


@media (max-width: $breakpoint-lg) {
  fm-insights-row-no-mobile {
    display: none;
  }
}
