.fm-report-trip-details-content {
  padding: 14px 14px 50px 14px;
}

.fm-report-trip-details-content .fm-input {
  margin: 14px 0;
}

.fm-report-trip-details-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 2px;
}

.fm-report-trip-details-info {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 2px;
}

.fm-report-trip-details-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 14px 0 28px 0;
  cursor: pointer;
}

.fm-report-trip-details-support {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 3px 0;
}

.fm-report-trip-details-support-label {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  padding: 0 2px;
}

.fm-report-trip-details-note {
  font-size: 14px;
  line-height: 18px;
  padding: 0 2px;
  color: $light-text-color;
}
