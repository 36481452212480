/* ------ Vehicle ------ */

.fm-vehicle {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* ------ Navigation ------ */

.fm-vehicle-navigation {
  width: 100%;
  height: 61px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $border-color;
  justify-content: space-between;
}

.fm-vehicle-navigation-left, .fm-vehicle-navigation-right {
  display: flex;
  flex-direction: row;
}

.fm-vehicle-navigation-action {
  background: #fff;
  font-size: 20px;
  color: $text-color;
  width: 50px;
  height: 50px;
  line-height: 26px;
  padding: 12px;
  text-align: center;
  margin: 5px 3px 5px 0;
  border: none;
}

.fm-vehicle-navigation-rego {
  background: #111111;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  width: 62px;
  border-radius: 3px;
  text-align: center;
  margin: 15px 0 15px 2px;
  line-height: 30px;
}

/* ------ Content ------ */

.fm-vehicle-content {
  overflow-y: scroll;
  flex: 1;
}

.fm-vehicle-section-header {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-section-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
}

.fm-vehicle-section-button {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  border-radius: 3px;
  margin: 1px 15px 1px 0;
  background: #f8f7f9;
  cursor: pointer;
}

.fm-vehicle-section-empty {
  font-size: 14px;
  color: #9C9B9D;
  margin: 8px 15px 5px 15px;
}

/* ------ Banners ------ */

.fm-vehicle-banner {
  padding: 12px 15px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  display: block;
}

.fm-vehicle-banner-critical {
  background: #EB4D4B;
}

.fm-vehicle-banner-inspection {
  background: #A55EEA;
}

.fm-vehicle-banner-info {
  background: #f39c12;
}

.fm-vehicle-banner-inuse {
  background: #00A8FF;
}

.fm-vehicle-banner-reserved {
  background: #F9CA24;
}

.fm-vehicle-banner-fallen {
  background: #f1c40f;
}

/* ------ Map ------ */

.fm-vehicle-map {
  height: 250px;
  background: #eee;
}

/* ------ Details ------ */

.fm-vehicle-details {
  display: flex;
  justify-content: space-between;
  padding: 22px 15px;
}

.fm-vehicle-details-qr {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

.fm-vehicle-details-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fm-vehicle-details-status {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
}

.fm-vehicle-details-updated {
  color: $light-text-color;
  font-size: 14px;
  margin-top: 8px;
}

.fm-vehicle-details-model {
  color: $light-text-color;
  font-size: 13px;
  margin-top: 8px;
  font-weight: 600;
}

/* ------ Controls ------ */

.fm-vehicle-controls {
  padding: 0 15px 10px 15px;
}

.fm-vehicle-controls-button {
  border: 0;
  cursor: pointer;
  display: block;
  background: #f8f7f9;
  padding: 10px 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 4px 0 10px 0;
  height: 40px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  text-align: center;
  flex: 1 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fm-vehicle-controls-button + .fm-vehicle-controls-button {
  margin-left: 10px;
}

.fm-vehicle-controls-group {
  display: flex;
}

/* ------ Status ------ */

.fm-vehicle-status {
  padding: 17px 0;
  display: flex;
  flex-wrap: wrap;
}

.fm-vehicle-status-item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14px;
}

.fm-vehicle-status-item-icon {
  border-radius: 50%;
  background: $light-background-color;
  padding: 12px;
  width: 42px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.fm-vehicle-status-item-text {
  font-size: 14px;
  line-height: 22px;
  margin: 7px 0 5px 0;
  text-align: center;
}

/* ------ Tasks ------ */

.fm-vehicle-tasks {
  display: flex;
  flex-direction: column;
}

.fm-vehicle-task-item {
  margin: 10px 15px;
  display: block;
  flex: 1;
}

.fm-vehicle-task-item-header {
  display: flex;
  align-items: center;
}

.fm-vehicle-task-item-status {
  background: red;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 0 6px 0 2px;
}

.fm-vehicle-task-item-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 7px;
  border-radius: 3px;
  line-height: 20px;
}

.fm-vehicle-task-item-created {
  flex: 1;
  text-align: right;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

.fm-vehicle-task-item-details {
  flex: 1;
  text-align: left;
  font-size: 14px;
  margin-top: 9px;
  padding: 1px;
  line-height: 20px;
}

/* ------ Tabs ------ */

.fm-vehicle-tabs {
  display: flex;
  overflow-x: scroll;
}

.fm-vehicle-tab {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
  margin: 12px 0 10px 0;
  opacity: 0.4;
  cursor: pointer;
  white-space: nowrap;
}

.fm-vehicle-tabs-loading {
  height: 2px;
}

.fm-vehicle-tab-active {
  opacity: 1.0;
}

.fm-vehicle-tab-content {
  padding: 7px 15px 50px 15px;
  min-height: 200px;
}

.fm-vehicle-tab-content-empty {
  text-align: center;
  font-size: 14px;
  color: #999;
  margin: 50px;
}

.fm-vehicle-tab-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.fm-vehicle-tab-row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-tab-row-title {
  font-size: 15px;
  padding: 5px 0;
  line-height: 20px;
}

.fm-vehicle-tab-row-detail {
  color: #999;
  font-size: 14px;
  padding: 5px 0;
  line-height: 20px;
}

.fm-vehicle-tab-row-tag {
  height: 24px;
  padding: 0;
  width: 125px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}

/* ------ Task ------ */

.fm-vehicle-tasks {
  margin-bottom: 25px;
  display: flex;
}

.fm-vehicle-task {
  display: flex;
  align-items: center;
  padding: 15px;
}

.fm-vehicle-task-type {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  flex: 0 0 16px;
}

.fm-vehicle-task-notes {
  margin: 0 10px 0 12px;
  font-size: 14px;
  line-height: 20px;
}

.fm-vehicle-task-date {
  flex: 1;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: $light-text-color;
}

/* ------ Old ------ */

/* SECTION */

.fm-vehicle-details-section {
  border-top: 1px solid $border-color;
  margin-top: 50px;
  position: relative;
  padding-bottom: 20px;
}

.fm-vehicle-details-section-title {
  width: 100px;
  background: #fff;
  text-align: center;
  margin: -10px auto 10px auto;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  color: $light-text-color;
}

.fm-vehicle-details-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.fm-vehicle-details-item-value {
  background: $light-background-color;
  padding: 10px 18px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.fm-vehicle-details-item-label {
  font-size: 16px;
  line-height: 20px;
}

.fm-vehicle-details-map {
  height: 300px;
  margin-top: 50px;
}

.fm-vehicle-details-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0;
}

/* POP UP */

.fm-vehicle-popup-subtitle {
  line-height: 20px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  margin: 10px 0 5px 0;
}

.fm-vehicle-popup-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;
}

.fm-vehicle-popup-buttons .fm-input {
  margin: 10px;
  width: auto;
}

.fm-vehicle-popup-button {
  cursor: pointer;
  display: block;
  flex: 1;
  background: $light-background-color;
  padding: 10px 18px;
  border-radius: 5px;
  margin: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  &:hover {
    background: $border-color;
  }
}

.fm-vehicle-popup-cancel-button {
  cursor: pointer;
  display: block;
  flex: 1 1;
  background: #F8F7F9;
  padding: 10px 18px;
  border-radius: 5px;
  margin: 30px 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: red;

  &:hover {
    background: $border-color;
  }
}

.fm-vehicle-popup-label {
  flex: 1;
  padding: 0 10px;
}

.fm-vehicle-popup-value {
  text-align: center;
  color: $light-text-color;
  padding: 0 10px;
}

.fm-vehicle-popup-firmware-row-install {
  cursor: pointer;
  display: block;
  background: $light-background-color;
  padding: 10px 18px;
  border-radius: 5px;
  margin: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  &:hover {
    background: $border-color;
  }
}

.fm-vehicle-popup-firmware-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-popup-firmware-row-version {
  font-size: 15px;
  padding: 0 15px;
}

.fm-vehicle-popup-firmware-row-description {
  padding: 5px 15px;
  font-size: 12px;
  color: #999;
}

.fm-vehicle-inspection-result {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 3px;
  width: 80px;
  text-align: center;
}

.fm-vehicle-details-section-more {
  cursor: pointer;
  display: block;
  background: #F8F7F9;
  padding: 7px 18px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin: 30px auto 0 auto;
  max-width: 200px;
  -webkit-transition: ease all 0.2s;
  transition: ease all 0.2s;
  text-align: center;
}

@media (max-width: $breakpoint-sm) {
  .fm-vehicle-details-top-preview {
    display: none;
  }
  .fm-vehicle-details-top-middle {
    padding: 0;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-vehicle-navigation {
    border-bottom-color: $border-color-dark;
    background: $background-color-dark;
  }
  .fm-vehicle-map {
    background: $light-background-color-dark;
  }
  .fm-vehicle-navigation-action, .fm-vehicle-navigation-back {
    background: $background-color-dark;
    color: $text-color-dark;
  }
  .fm-vehicle-status-item-icon, .fm-vehicle-section-button, .fm-vehicle-controls-button {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
}
