.fm-invitation {
  min-height: 100%;
  min-height: 100vh;
}

.fm-invitation-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  margin: 25px auto;
}

.fm-invitation-container .fm-text {
  text-align: center;
  margin: 30px 0 40px 0;
}

.fm-invitation-logo {
  background: url('https://storage.googleapis.com/flamingo-static/images/admin/wordmark-black.svg') 50% 50% no-repeat;
  height: 40px;
  background-size: 120px;
  margin: 10px 0;
}

.fm-invitation-title {
  font-size: 18px;
  color: $light-text-color;
  text-align: center;
}

.fm-invitation-form {
  margin: 60px 0;
}

.fm-invitation .fm-input-label, .fm-invitation .fm-input {
  text-align: center;
}

.fm-invitation .fm-button {
  margin-top: 50px;
}

.fm-invitation .fm-loading {
  margin-top: 50px;
}

.fm-invitation .fm-button .fm-loading {
  margin-top: 0;
}

.fm-invitation #recaptchaContainer > div:first-child {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
