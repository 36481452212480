.fm-firmware-upgrade-warning {
  padding: 15px 15px 5px 15px;
  line-height: 22px;
}

.fm-firmware-upgrade-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.fm-firmware-upgrade-list {
  padding: 15px;
}

.fm-firmware-upgrade-name {
  font-weight: 600;
  line-height: 22px;
}

.fm-firmware-upgrade-type {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 10px;
  line-height: 18px;
  text-transform: uppercase;
}

.fm-firmware-upgrade-button {
  font-size: 14px;
  color: #666;
  background: #eee;
  font-weight: 400;
  text-align: center;
  padding: 2px 8px 2px 10px;
  border-radius: 3px;
  line-height: 22px;
  cursor: pointer;
  height: 26px;
  flex: 0 0 75px;
  margin-left: 10px;
}

.fm-firmware-upgrade-top {
  display: flex;
  align-items: center;
}

.fm-firmware-upgrade-button .fa {
  color: #999;
  margin-left: 6px;
  line-height: 20px;
  font-size: 13px;
}

.fm-firmware-upgrade-description {
  font-size: 14px;
  color: $light-text-color;
  line-height: 18px;
  margin-top: 10px;
}
