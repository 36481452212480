.fm-user-edit-content {
  padding: 0 15px 30px 15px;
}

.fm-user-edit-input-row {
  display: flex;
  flex-direction: row;
}

.fm-user-edit-input, .fm-user-edit-input-row  {
  margin: 20px 0 25px 0;
  flex: 1;
}

.fm-user-edit-input-row .fm-user-edit-input {
  margin: 0;
}

.fm-user-edit-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 13px 0 35px 0;
  cursor: pointer;
}

.fm-user-edit-status {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $text-color;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 13px 0;
  cursor: pointer;
}

.fm-user-edit-button:disabled, .fm-user-edit-status:disabled {
  color: #ccc;
}
