.fm-task-transits-item {
  margin: 30px 15px;
  display: flex;
  align-items: center;
}

.fm-task-transits-item-rego {
  font-size: 14px;
  color: #fff;
  background: #000;
  font-weight: 600;
  width: 55px;
  text-align: center;
  padding: 2px 0;
  border-radius: 3px 0 0 3px;
  flex: 0 0 55px;
  line-height: 20px;
}

.fm-task-transits-item-status {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
  border-left: 1px solid #666;
}

.fm-task-transits-item-time {
  flex: 1 1;
  text-align: right;
  padding: 0;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}
