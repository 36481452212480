.fm-user-app-map {
  height: 70vh;
}

.fm-user-app-items {
  margin: 15px;
}

.fm-user-app-item {
  display: block;
  margin: 10px 0;
}

.fm-user-app-items-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 0;
}

.fm-user-app-item-label {
  text-align: left;
  font-size: 15px;
  padding: 1px;
  line-height: 20px;
}
