.fm-schedule-working {
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-schedule-container {
  margin-bottom: 30px;
}

.fm-schedule-heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 15px;
}

.fm-schedule-event {
  margin: 15px;
}

.fm-schedule-event-card {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 9px 9px 10px;
}

.fm-schedule-event-title {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  line-height: 18px;
}

.fm-schedule-event-time {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 16px;
  margin-top: 4px;
}

.fm-schedule-event-user {
  height: 38px;
  width: 38px;
  border-radius: 19px;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  transition: width ease 0.5s;
}

.fm-schedule-event-user-initials {
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  overflow: hidden;
  width: auto;
}

.fm-schedule-event-user-full {
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
}

.fm-schedule-event-user:hover {
  width: auto;
}

.fm-schedule-event-user:hover .fm-schedule-event-user-initials {
  width: 0;
}

.fm-schedule-event-user:hover .fm-schedule-event-user-full {
  width: auto;
  padding: 0 16px;
}

.fm-schedule-event-heading {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
}

.fm-schedule-working-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}

.fm-schedule-working-job {
  font-size: 14px;
  line-height: 25px;
  color: $light-text-color;
}

.fm-schedule-working-timing {
  font-size: 14px;
  color: $light-text-color;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fm-schedule-working-status {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin: 6px 0;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-schedule-event-title, .fm-schedule-event-time {
    color: $text-color;
  }
  .fm-schedule-event-user {
    background: $background-color-dark;
  }
}
