.fm-maintenance-guides .fm-input-container {
  margin: 15px;
}

.fm-maintenance-guides-item {
  flex: 1;
  display: flex;
  align-items: center;
  margin: 30px 15px;
}

.fm-maintenance-guides-item-image {
  width: 220px;
  height: 120px;
  background-size: cover;
  background-position: 50%;
  border-radius: 3px;
  background-color: #333;
}

.fm-maintenance-guides-item-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 30px;
  line-height: 22px;
}

/* ------ Solutions ------ */

.fm-maintenance-guides-solutions {
  display: flex;
  margin-top: 15px;
  overflow-x: scroll;
  padding-bottom: 12px;
}

.fm-maintenance-guides-solution {
  margin-right: 10px;
}

.fm-maintenance-guides-solution:first-child {
  margin-left: 15px;
}

.fm-maintenance-guides-solution:last-child {
  margin-right: 15px;
}

.fm-maintenance-guides-solution-fault {
  background: $light-background-color;
  border-radius: 3px;
  width: 50px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
}

/* ------ Sizing ------ */

@media (max-width: $breakpoint-sm) {
  .fm-maintenance-guides-item {
    position: relative;
    margin: 30px 15px;
  }

  .fm-maintenance-guides-item-image {
    width: 100%;
    height: 200px;
  }

  .fm-maintenance-guides-item-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 15px;
    background: rgba(0,0,0,0.6);
    color: #fff;
    border-radius: 3px;
  }
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-maintenance-guides-solution-fault {
    background-color: $light-background-color-dark;
  }
}
