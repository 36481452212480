.fm-analytics-hubs-form {
  display: flex;
  margin: 20px 15px 15px 15px;
  gap: 15px;
}

.fm-analytics-hubs-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
  width: 150px;
  flex: 0 0 150px;
}

.fm-analytics-hubs-empty {
  color: $light-text-color;
  text-align: center;
  font-size: 14px;
  margin-top: 100px;
}

.fm-analytics-hubs-item {
  margin: 20px 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;
}

.fm-analytics-hubs-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-analytics-hubs-item-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
}

.fm-analytics-hubs-item-result {
  display: flex;
  flex: 0 0 120px;
}

.fm-analytics-hubs-item-ratio {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 0;
  border-radius: 3px 0 0 3px;
  width: 55px;
  flex: 0 0 55px;
  background: #111;
  text-align: center;
}

.fm-analytics-hubs-item-hours {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 2px 0;
  border-radius: 0 3px 3px 0;
  width: 65px;
  flex: 0 0 65px;
  text-align: center;
}

.fm-analytics-hubs-item-content {
  margin-top: 5px;
}

.fm-analytics-hubs-item-address {
  font-size: 15px;
  color: $light-text-color;
  line-height: 20px;
}

.fm-analytics-hubs-item-stats {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.fm-analytics-hubs-item-stat {
  font-size: 15px;
  color: $light-text-color;
  line-height: 20px;
}

.fm-analytics-hubs-item-stat-spacer {
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background: $light-text-color;
}

.fm-analytics-hubs-statistics {
  background: $light-background-color;
  border-radius: 3px;
  margin: 20px 15px 15px 15px;
  gap: 15px;
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  line-height: 18px;
  padding: 10px 12px;
}

.fm-analytics-hubs-statistics-item {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  text-align: center;
}

.fm-analytics-hubs-list {
  margin-top: 25px;
}

@media (max-width: $breakpoint-sm) {
  .fm-analytics-hubs-form {
    flex-direction: column;
  }
  .fm-analytics-hubs-button {
    flex: 1;
    width: 100%;
  }
  .fm-analytics-hubs-item-stats {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 8px;
  }
  .fm-analytics-hubs-item-stat-spacer {
    display: none;
  }
  .fm-analytics-hubs-statistics {
    gap: 10px;
    flex-direction: column;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-analytics-hubs-item {
    border-bottom: 1px solid $border-color-dark;
  }
}
