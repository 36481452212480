.fm-hub-add-content {
  display: flex;
  flex-direction: row;
}

.fm-hub-add-left {
  padding: 15px 10px 0 15px;
  flex: 1;
}

.fm-hub-add-notes {
  margin: 15px 0;
}

.fm-hub-add-note {
  font-size: 14px;
  line-height: 20px;
  color: $light-text-color;
  margin: 10px 0 10px 20px;
}

.fm-hub-add-map {
  width: 100%;
  height: 600px;
  border-radius: 5px;
}

.fm-hub-add-form {
  padding: 0 15px 30px 15px;
  flex: 1;
}

.fm-hub-add-input-row {
  display: flex;
  flex-direction: row;
}

.fm-hub-add-input, .fm-hub-add-input-row  {
  margin: 20px 0 25px 0;
  flex: 1;
}

.fm-hub-add-input-row .fm-hub-add-input {
  margin: 0;
}

.fm-hub-add-input-row .fm-hub-add-input:nth-child(2) {
  margin-left: 20px;
}

.fm-hub-add-input-note {
  font-size: 13px;
  margin-top: 10px;
  line-height: 18px;
  color: $light-text-color;
}

.fm-hub-add-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 30px 0;
  cursor: pointer;
}

.fm-hub-add-input-target {
  display: flex;
  margin: 0 0 15px 0;
  gap: 10px;
}

.fm-hub-add-input-target .fm-input {
  flex: 1;
}

.fm-hub-add-input-target .fm-input.fm-input-select {
  flex: 2;
}

.fm-hub-add-input-target-delete {
  background: $light-background-color;
  height: 41px;
  width: 41px;
  flex: 0 0 41px;
  line-height: 41px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
}

.fm-hub-add-input-target-add {
  background: $light-background-color;
  height: 41px;
  line-height: 41px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-weight: 600;
}

@media (max-width: $breakpoint-md) {
  .fm-hub-add-content {
    flex-direction: column;
  }
  .fm-hub-add-map {
    padding: 0;
    flex: 0 0 300px;
    height: 300px;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-hub-add-input-row .fm-hub-add-input:nth-child(2) {
    margin-left: 0;
  }

  .fm-hub-add-input-row {
    display: block;
  }

  .fm-hub-add-input, .fm-hub-add-input-row  {
    margin: 0;
  }

  .fm-hub-add-input-row .fm-hub-add-input {
    margin: 20px 0 25px 0;
  }
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-hub-add-input-target-delete, .fm-hub-add-input-target-add {
    background: $light-background-color-dark;
    color: #fff;
  }
}
