@import './components/support-ticket/support-ticket.scss';
@import './components/support-activity/support-activity.scss';
@import './components/support-call/support-call.scss';

.fm-support {
  padding-bottom: 20px;
}

.fm-tickets-content {
  padding: 15px;
}

.fm-tickets-search .fm-input-container {
  margin: 0 0 15px 0;
}

.fm-tickets-tabs {
  border: 3px solid $light-background-color;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-tickets-tab {
  flex: 1;
  text-align: center;
  line-height: 20px;
  padding: 9px;
  font-size: 15px;
  cursor: pointer;
}

.fm-tickets-tab-active {
  background: $light-background-color;
  cursor: default;
  font-weight: 600;
}

.fm-support-on-call-popup {
  padding: 0 20px;
}

.fm-support-tickets-ticket-created {
  color: $light-text-color;
  font-size: 14px;
  margin-top: 9px;
}

.fm-support-tickets-ticket-overdue {
  color: #eb4d4b;
  font-weight: 600;
}

.fm-support-tickets {
  padding: 10px 0;
}

.fm-support-tickets-ticket {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 10px 35px 10px;
}

.fm-support-tickets-ticket-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  min-width: 110px;
}

.fm-support-tickets-ticket-subject {
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}

.fm-support-tickets-ticket-type {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  background: #000000;
  margin-top: 3px;
}

.fm-support-tickets-ticket-status {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background: red;
  margin: 4px 0;
  margin-top: 4px;
}

.fm-support-tickets-ticket-name {
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
}

.fm-support-tickets-ticket-left-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0;
  height: 22px;
}

.fm-support-tickets-ticket-preview {
  font-size: 14px;
  line-height: 22px;
  color: $light-text-color;
  margin-left: 10px;
  overflow: hidden;
  flex: 1;
  height: 22px;
  word-break: break-all;
}

.fm-ticket-reply .fm-input-container {
  margin: 20px 0;
}

.fm-ticket-reply .fm-input-textarea {
  min-height: 200px;
  padding: 10px 15px;
}

.fm-support-calls-call-icon {
  font-size: 14px;
  margin-left: 10px;
}

@media (max-width: $breakpoint-sm) {
  .fm-tickets-tab {
    flex: none;
    padding: 8px 12px;
  }
  .fm-support-tickets-ticket {
    margin: 20px 0 30px 0;
  }
  .fm-support-tickets-ticket-subject {
    font-size: 16px;
  }
}

/* TICKET PAGE */

.fm-ticket-content {
  border-bottom: 1px solid $border-color;
}

.fm-ticket-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.fm-ticket-top-status, .fm-ticket-top-type, .fm-ticket-top-open {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 10px;
}

.fm-ticket-top-open {
  background: #e67e22;
}

.fm-ticket-top-created {
  color: #9c9b9d;
  font-size: 14px;
  flex: 1;
  text-align: right;
}

.fm-ticket-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.fm-ticket-boxes {
  display: flex;
  align-items: flex-start;
}

.fm-ticket-box-title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
}

.fm-ticket-box-detail {
  font-size: 15px;
  line-height: 27px;
  display: block;
}

.fm-ticket-box {
  margin: 20px 0;
  flex: 1;
}

.fm-ticket-notes {
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  color: $light-text-color;
  margin-bottom: 20px;
}

.fm-ticket-item-content {
  background: $light-background-color;
  border-radius: 5px;
  padding: 10px 15px;
  line-height: 25px;
  white-space: pre-line;
  max-width: 100%;
  overflow: hidden;
  word-break: break-word;
}

.fm-ticket-item.fm-ticket-item-external {
  margin: 20px 20% 20px 0;
}

.fm-ticket-item.fm-ticket-item-internal, .fm-ticket-item.fm-ticket-item-note {
  margin: 20px 0 20px 20%;
}

.fm-ticket-item-date {
  color: $light-text-color;
  font-size: 14px;
  flex: 1 1;
  font-style: italic;
  line-height: 30px;
  margin-left: 15px;
}

.fm-ticket-item.fm-ticket-item-internal .fm-ticket-item-content {
  background: #fff;
  border: 1px solid #eee;
}

.fm-ticket-item.fm-ticket-item-internal .fm-ticket-item-date, .fm-ticket-item.fm-ticket-item-note .fm-ticket-item-date {
  text-align: right;
  margin-right: 15px;
}

.fm-ticket-item.fm-ticket-item-note .fm-ticket-item-content {
  background: #fff8dc;
  border: 1px solid #f1c40f;
}

.fm-ticket-item-content img {
  max-width: 100%;
}

.fm-ticket-reply {
  padding-bottom: 50px;
}

.fm-ticket-reply-actions {
  display: flex;
  padding: 0 0 20px 0;
}

.fm-ticket-reply-action {
  display: block;
  background: #f8f7f9;
  padding: 10px 20px;
  font-size: 15px;
  letter-spacing: 0.2px;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #eee;
  }
}

.fm-ticket-reply-actions-spacer {
  display: block;
  flex: 1;
}

.fm-ticket-reply-action-r {
  background: #6ab04c;
  margin-right: 15px;
}

.fm-ticket-reply-action-rs {
  margin-right: 15px;
  background: #686de0;
}

.fm-ticket-reply-action-s {
  background: #535c68;
}

.fm-ticket-reply-action-n {
  background: #f1c40f;
}

.fm-ticket-reply-actions-input .fm-input-container {
  margin: 0 15px 0 0;
  width: 150px;
}

@media (max-width: $breakpoint-sm) {
  .fm-ticket-boxes {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #E8E7EA;
    flex-direction: column;
  }
  .fm-ticket-box {
    margin: 15px 0;
  }
  .fm-ticket-item.fm-ticket-item-external {
    margin: 30px 10% 30px 0;
  }
  .fm-ticket-box-title {
    font-size: 16px;
  }
  .fm-ticket-box-detail {
    font-size: 14px;
    line-height: 20px;
  }
}

/* CALLS */

.fm-support-calls-list {
  padding: 10px 15px;
}

.fm-support-calls-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin: 40px 0 10px 0;
}

.fm-support-calls-call {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.fm-support-calls-call-phone {
  flex: 1;
}

.fm-support-calls-call-user {
  flex: 1;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-support-calls-call-date {
  color: $light-text-color;
  font-size: 14px;
  text-align: right;
}

@media (max-width: $breakpoint-sm) {
  .fm-ticket-reply-actions {
    flex-direction: column;
  }
  .fm-ticket-reply-action {
    margin-bottom: 10px;
    text-align: center;
  }
  .fm-ticket-reply-action-rs, .fm-ticket-reply-action-r, .fm-ticket-reply-action-n {
    margin-right: 0;
  }
  .fm-ticket-reply-actions-input .fm-input-container {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .fm-tickets-tab-total {
    display: none;
  }
}

/* On Call */

.fm-oncall {
  padding: 5px 0 50px 0;
}

.fm-oncall-actions {
  display: flex;
  margin: 0 15px 30px 15px;
}

.fm-oncall-action-schedule {
  border: 0;
  cursor: pointer;
  display: block;
  color: #fff;
  background: #686de0;
  padding: 10px 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  height: 40px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  text-align: center;
  flex: 1 1;
}

.fm-oncall-action-now {
  border: 0;
  cursor: pointer;
  display: block;
  color: #fff;
  background: #6ab04c;
  padding: 10px 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  height: 40px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  text-align: center;
  flex: 1 1;
  margin-right: 10px;
}

.fm-oncall-action-loading {
  background: $border-color;
}

.fm-oncall-date {
  padding: 15px;
}

.fm-oncall-input {
  border: 0;
  font-size: 16px;
  color: #222222;
  background: #F8F7F9;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
}

.fm-oncall-staff {
  margin: 0 15px;
}

.fm-oncall-staff-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #E8E7EA;
  cursor: pointer;
}

.fm-oncall-staff-result-name {
  font-size: 16px;
  line-height: 30px;
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.fm-oncall-staff-result-phone {
  text-align: right;
  font-size: 14px;
  line-height: 30px;
  color: #9C9B9D;
}

.fm-oncall-staff-result-selected {
  font-size: 20px;
  margin-left: 20px;
}

.fm-oncall-staff-result:last-child {
  border-bottom: none;
}

.fm-oncall-schedule {
  margin: 0 15px;
}

.fm-oncall-schedule-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #E8E7EA;
}

.fm-oncall-schedule-change-name {
  font-size: 16px;
  line-height: 30px;
  flex: 1 1;
}

.fm-oncall-schedule-change-phone {
  text-align: right;
  font-size: 14px;
  line-height: 30px;
  color: #9C9B9D;
}

.fm-oncall-schedule-change-date {
  text-align: right;
  font-size: 14px;
  line-height: 30px;
  color: #9C9B9D;
  margin-left: 20px;
}

.fm-oncall-schedule-change:last-child {
  border-bottom: none;
}

.fm-oncall-schedule-empty {
  font-size: 14px;
  color: #9C9B9D;
  margin: 8px 0 5px 0;
}

@media (prefers-color-scheme: dark) {
  .fm-tickets-tabs {
    border-color: $light-background-color-dark;
  }
  .fm-tickets-tab-active, .fm-ticket-item-content, .fm-ticket-item.fm-ticket-item-note .fm-ticket-item-content {
    background: $light-background-color-dark;
  }
  .fm-ticket-item.fm-ticket-item-internal .fm-ticket-item-content {
    border-color: $border-color-dark;
    background: $background-color-dark;
  }
}
