.fm-performance-overview {
  display: flex;
  flex-direction: column;
}

.fm-performance-overview-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 9px;
}

.fm-performance-overview-options .fm-input-container {
  margin: 0;
  flex: 1;
  margin: 0 6px;
}

.fm-performance-overview-options-go {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  color: #222222;
  background: #F8F7F9;
  border-radius: 5px;
  padding: 10px 15px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0 6px;

  &:hover {
    background: #F4F3F4;
  }
}

.fm-performance-overview-list {
  flex: 1;
  padding: 0 15px 50px 15px;
  overflow-x: scroll;
}

.fm-performance-overview-row-value {
  font-size: 16px;
  text-align: right;
  border-top: 1px solid $border-color;
}

.fm-performance-overview-column-header {
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0 10px 20px;
  text-align: right;
}

.fm-performance-overview-row-name {
  font-size: 15px;
  line-height: 20px;
  padding: 10px 0;
  width: 140px;
  border-top: 1px solid $border-color;
}

.fm-performance-overview-table {
  border-collapse: collapse;
  width: 100%;
}

.fm-performance-overview-row:hover td {
  background: $light-background-color;
  cursor: pointer;
}

.fm-performance-overview-row-total {
  font-size: 16px;
  text-align: right;
  border-top: 1px solid #E8E7EA;
  font-weight: 700;
}

.fm-performance-overview-row-total-label {
  font-size: 15px;
  line-height: 20px;
  padding: 10px 0;
  width: 140px;
  border-top: 1px solid #E8E7EA;
  font-weight: 700;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-performance-overview-row:hover td {
    background-color: $light-background-color-dark;
  }
  .fm-performance-overview-row-name, .fm-performance-overview-row-value {
    border-top-color: $border-color-dark;
  }
}
