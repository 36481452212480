.ff-file {
  margin: 0;
}

.ff-file-label {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.ff-file-container {
  background: $light-background-color;
  border-radius: 3px;
  position: relative;
  height: 40px;
  display: flex;
  overflow: hidden;
}

.ff-file-text {
  margin: 10px 5px;
  line-height: 20px;
  font-size: 14px;
}

.ff-file-input {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 99;
}

.ff-file-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0%;
  height: 2px;
  background: $primary;
  transition: ease all 0.2s;
}

.ff-file-preview {
  margin: 5px;
  height: 30px;
  width: 40px;
  border-radius: 3px;
  background-color: #dbdfe4;
  // background-image: url('/assets/images/file.png');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.ff-file-container-uploaded {
  // background-image: url('/assets/images/tick.svg');
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center right 10px;
}

.ff-file-android {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  z-index: 100;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.ff-file-android-input {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 102;
}

.ff-file-android  i.fa.fa-camera {
  line-height: 40px;
  text-align: center;
  display: block;
  font-size: 16px;
  z-index: 101;
  cursor: pointer;
  width: 40px;
}

.ff-file-isandroid .ff-file-text {
  padding-right: 40px;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .ff-file-container {
    background-color: $light-background-color-dark;
  }
}
