.fm-incident-view-subtitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 15px 2px 15px;
}

/* ------ Relations ------ */

.fm-incident-view-relations {
  display: flex;
  margin: 15px 20px 20px 20px;
}

.fm-incident-view-relation {
  flex: 1;
}

.fm-incident-view-relation-row {
  height: 36px;
}

.fm-incident-view-relation-label {
  font-weight: 600;
  padding: 8px 10px 8px 0;
  line-height: 18px;
  font-size: 14px;
}

.fm-incident-view-relation-value {
  padding: 8px 0;
  line-height: 18px;
  font-size: 14px;
}

.fm-incident-view-relation-dot {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  margin: 2px 12px 2px 0;
  background: $light-background-color;
  display: inline-block;
  vertical-align: bottom;
}

@media (max-width: $breakpoint-md) {
  .fm-incident-view-relations {
    flex-direction: column;
  }
  .fm-incident-view-relation-table {
    width: 100%;
    table-layout: fixed;
    max-width: 100%;
  }
  .fm-incident-view-relation:not(:first-child) {
    margin-left: 25px;
  }
  .fm-incident-view-relation-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* ------ Duration ------ */

.fm-incident-view-duration {
  padding: 0 15px;
  margin-bottom: 30px;
}

.fm-incident-view-duration-inner {
  background: $light-background-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
}

.fm-incident-view-duration-line {
  position: absolute;
  height: 1px;
  background: $border-color;
  top: 17px;
  right: 0;
  left: 0;
}

.fm-incident-view-duration-time {
  font-size: 14px;
  line-height: 35px;
  padding: 0 15px;
  background: $light-background-color;
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-incident-view-duration-value {
  font-size: 14px;
  line-height: 35px;
  padding: 0 15px;
  font-weight: 600;
  background: $light-background-color;
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: $breakpoint-sm) {
  .fm-incident-view-duration-line {
    display: none;
  }
  .fm-incident-view-duration-value {
    padding: 0;
  }
}

/* ------ Outcome ------ */

.fm-incident-view-outcome {
  font-size: 15px;
  padding: 10px 15px 25px 15px;
}

/* ------ Feed ------ */

.fm-incident-view-activity-feed {
  padding: 5px 0;
  position: relative;
}

.fm-incident-view-activity-feed-line {
  position: absolute;
  width: 2px;
  top: 30px;
  bottom: -15px;
  left: 39px;
  z-index: 1;
  background: $light-background-color;
}

.fm-incident-view-activity-item {
  display: flex;
  align-items: flex-start;
  padding: 15px;
}

.fm-incident-view-activity-item-icon {
  height: 50px;
  width: 50px;
  background: $light-background-color;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  border-radius: 25px;
  z-index: 99;
  position: relative;
  flex: 0 0 50px;
}

.fm-incident-view-activity-item-image {
  border-radius: 3px;
  max-width: 700px;
  max-height: 500px;
  width: 100%;
}

.fm-incident-view-activity-item-details {
  padding: 0 15px 0 20px;
}

.fm-incident-view-activity-item-event {
  font-style: italic;
  font-size: 15px;
  line-height: 22px;
  white-space: pre-line;
  max-width: 100%;
  overflow: hidden;
  word-break: break-word;
}

.fm-incident-view-activity-item-text {
  font-size: 15px;
  line-height: 22px;
  white-space: pre-line;
  max-width: 100%;
  overflow: hidden;
  word-break: break-word;
}

.fm-incident-view-activity-item-bottom {
  padding: 10px 0;
  font-size: 14px;
  color: $light-text-color;
}

.fm-incident-view-activity-item-icon-insights {
  position: absolute;
  right: 3px;
  bottom: 3px;
  height: 10px;
  width: 10px;
  background-color: #4B7BEC;
  border-radius: 5px;
}

/* ------ Composer ------ */

.fm-incident-view-activity-composer {
  padding: 15px;
  margin-bottom: 50px;
  z-index: 99;
}

.fm-incident-view-activity-composer-controls {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
}

.fm-incident-view-activity-composer-left {
  flex: 1;
  display: flex;
  align-items: center;
}

.fm-incident-view-activity-composer-left .fm-input-checkbox-container {
  margin: 0 12px 0 0;
}

.fm-incident-view-activity-composer-label {
  font-size: 15px;
  line-height: 25px;
  cursor: pointer;
}

.fm-incident-view-activity-composer-submit {
  border: 0;
  font-size: 16px;
  color: #fff;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 30px;
  transition: ease all 0.1s;
  display: block;
  margin-left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
}

@media (max-width: $breakpoint-sm) {
  .fm-incident-view-activity-composer-submit {
    flex: 1;
  }
  .fm-incident-view-activity-composer-left {
    display: none;
  }
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-incident-view-activity-item-icon, .fm-incident-view-activity-feed-line {
    background-color: $light-background-color-dark;
  }
  .fm-incident-view-duration-inner, .fm-incident-view-duration-time, .fm-incident-view-duration-value {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
}
