.fm-inspection-new-content {
  overflow: hidden;
  padding: 0 20px;
}

/* ----- CARDS ----- */

.fm-inspection-new-cards {
  margin: 20px auto;
  max-width: 400px;
  width: 100%;
  position: relative;
  height: 430px;
}

.fm-inspection-new-card {
  height: 420px;
  border: 1px solid $border-color;
  border-radius: 3px;
  background: #fff;
  transition: ease all 0.5s;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 10px;
  bottom: 0px;
  width: 96%;
  left: 2%;
  z-index: 1;
}

.fm-inspection-new-card-pending-0 {
  z-index: 3;
  top: 0;
  bottom: 10px;
  width: 100%;
  left: 0;
}

.fm-inspection-new-card-pending-1 {
  top: 5px;
  bottom: 5px;
  z-index: 2;
  width: 98%;
  left: 1%;
}

.fm-inspection-new-card-complete {
  z-index: 4;
  top: -1200px;
  bottom: auto;
  width: 100%;
}

.fm-inspection-new-card-complete-failed {
  top: 0;
  bottom: 10px;
  left: -1700px;
  left: -120vw;
  background: #e84118;
}

.fm-inspection-new-card-complete-passed {
  top: 0;
  bottom: 10px;
  left: 1200px;
  left: 100vw;
  background: #4cd137;
}

/* ----- ACTIONS ----- */

.fm-inspection-new-actions {
  margin: 20px auto;
  max-width: 400px;
  width: 100%;
}

.fm-inspection-new-actions-grade {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.fm-inspection-new-action {
  flex: 1;
  border-radius: 3px;
  background: #4cd137;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  padding: 5px 0;
  cursor: pointer;
  touch-action: manipulation;
}

.fm-inspection-new-action-fail {
  background: #e84118;
  margin-right: 10px;
}

.fm-inspection-new-next {
  display: block;
  width: 100%;
  border-radius: 3px;
  background: $text-color;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  padding: 5px 0;
  cursor: pointer;
  touch-action: manipulation;
}

.fm-inspection-new-back {
  display: block;
  width: 100%;
  border-radius: 3px;
  background: $light-background-color;
  color: #222;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  padding: 5px 0;
  margin: 15px 0;
  cursor: pointer;
}

/* ----- CARD CONTENT ----- */

.fm-inspection-new-card-group-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
}

.fm-inspection-new-card-group-name {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 5px;
  text-align: center;
}

.fm-inspection-new-card-content {
  flex: 1;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fm-inspection-new-card-details {
  padding: 15px 20px;
}

.fm-inspection-new-card-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 5px;
}

.fm-inspection-new-card-description {
  font-size: 15px;
  line-height: 22px;
  padding: 5px 0;
}

.fm-inspection-new-card-directive {
  background: #e8411861;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 8px;
  border-radius: 3px;
  margin: 8px 0 3px 0;
  font-weight: 600;
}

/* ----- CARD FEATURES ----- */

.fm-inspection-new-card-content-photo {
  height: 100%;
  width: 100%;
  background-color: $light-background-color;
  border-radius: 5px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: $border-color;
}

.fm-inspection-new-card-content-battery {
  height: 90px;
  width: 200px;
  border: 2px solid $border-color;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 5px;
}

.fm-inspection-new-card-content-battery-range {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  padding: 5px 0;
  font-weight: 600;
}

.fm-inspection-new-card-content-battery-percent {
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  padding: 2px 0;
}

.fm-inspection-new-card-content-battery-inner {
  background: #4cd137;
  height: 100%;
  border-radius: 3px;
  min-width: 2%;
  max-width: 100%;
}

.fm-inspection-new-card-content-qr {
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  padding: 2px 0;
}

.fm-inspection-new-card-content-rego {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  padding: 5px 0;
  font-weight: 600;
}

.fm-inspection-new-card-content-map {
  flex: 1;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.fm-inspection-new-card-content-map-subtitle {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  width: 100%;
  color: $light-text-color;
}

.fm-inspection-new-card-content-button {
  display: block;
  border-radius: 3px;
  background: $light-background-color;
  color: $text-color;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  padding: 10px 20px;
  margin: 15px 0;
  cursor: pointer;
}

.fm-inspection-new-card-content .ff-file-container {
  height: 100%;
  align-items: center;
}

.fm-inspection-new-card-content .ff-file {
  width: 100%;
  flex: 1;
}

.fm-inspection-new-card-content .ff-file-preview {
  margin: 5px 10px 5px 20px;
  height: 80px;
  width: 80px;
}

.fm-inspection-new-card-content .ff-file-text {
  font-size: 16px;
}

/* ----- REVIEWS ----- */

.fm-inspection-new-review {
  margin-bottom: 40px;
}

.fm-inspection-new-review-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.fm-inspection-new-review-status-rego {
  background: #111;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  width: 62px;
  border-radius: 3px;
  text-align: center;
  line-height: 30px;
  height: 30px;
}

.fm-inspection-new-review-status-result {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  display: inline-block;
}

.fm-inspection-new-review-status-result-failed {
  background: #e84118;
}

.fm-inspection-new-review-status-result-passed {
  background: #4cd137;
}

.fm-inspection-new-review-notes-textarea {
  border: 0;
  font-size: 16px;
  color: #222;
  background: #f8f7f9;
  border-radius: 3px;
  padding: 10px 12px;
  transition: all .1s ease;
  display: block;
  width: 100%;
  font-family: -apple-system,BlinkMacSystemFont,San Francisco,Lato,Segoe UI,Helvetica Neue,sans-serif;
  line-height: 21px;
  max-width: 100%;
  resize: none;
  margin: 15px 0;
}

.fm-inspection-new-review-notes-prefill-item {
  margin: 0 10px 10px 0;
  border-radius: 3px;
  background-color: $border-color;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 8px;
  cursor: pointer;
  display: inline-block;
}

.fm-inspection-new-review-notes-title, .fm-inspection-new-review-tickets-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 5px 0;
}

.fm-inspection-new-review-notes-description {
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0;
}

.fm-inspection-new-review-notes-prefill {
  margin: 20px 0;
}

.fm-inspection-new-review-upload {
  border-radius: 3px;
  background-color: $border-color;
  line-height: 20px;
  padding: 5px 8px;
  position: relative;
  margin-bottom: 20px;
  height: 30px;
  z-index: 0;
  overflow: hidden;
}

.fm-inspection-new-review-upload-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $primary;
  transition: ease all 0.1s;
}

.fm-inspection-new-review-upload-label {
  font-size: 14px;
  line-height: 20px;
  z-index: 9;
  color: #fff;
  position: relative;
}

.fm-inspection-new-review-tickets {
  margin-bottom: 25px;
}

.fm-inspection-new-review-tickets-note {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  color: $light-text-color;
}

.fm-inspection-new-review-tickets-item {
  margin: 15px 0;
  display: block;
  flex: 1;
}

.fm-inspection-new-review-tickets-item-header {
  display: flex;
  align-items: center;
}

.fm-inspection-new-review-tickets-item-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #639;
  padding: 2px 7px;
  border-radius: 3px;
  line-height: 20px;
}

.fm-inspection-new-review-tickets-item-created {
  flex: 1;
  text-align: right;
  font-size: 14px;
  color: $light-text-color;
  line-height: 20px;
}

.fm-inspection-new-review-tickets-item-content {
  display: flex;
  margin-top: 9px;
}

.fm-inspection-new-review-tickets-item-details {
  flex: 1;
  text-align: left;
  font-size: 14px;
  padding: 1px;
  line-height: 20px;
}

.fm-inspection-new-review-tickets-item-resolve {
  height: 22px;
  width: 22px;
  background: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: center;
  color: #DDD;

  &:hover {
    color: #000;
  }
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-inspection-new-card {
    background: $background-color-dark;
    border-color: $border-color-dark;
  }
  .fm-inspection-new-back {
    background: $border-color-dark;
    color: #fff;
  }
  .fm-inspection-new-card-content-photo {
    background-color: $light-background-color-dark;
  }
  .fm-inspection-new-card-complete-failed {
    background: #e84118;
  }
  .fm-inspection-new-card-complete-passed {
    background: #4cd137;
  }
  .fm-inspection-new-card-content-battery {
    border-color: $border-color-dark;
  }
  .fm-inspection-new-review-notes-textarea {
    background: $light-background-color-dark;
    color: #fff;
  }
  .fm-inspection-new-review-notes-prefill-item, .fm-inspection-new-review-upload {
    background: $border-color-dark;
  }
}
