.fm-analytics {
  padding-bottom: 50px;
}

.fm-analytics-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-analytics-options .fm-input-container {
  flex: 1;
  margin: 0 15px 0 0;
}

.fm-analytics-options-go {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  color: #222222;
  background: #F8F7F9;
  border-radius: 5px;
  padding: 10px 15px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;

  &:hover {
    background: #F4F3F4;
  }
}

.fm-analytics-first {
  font-size: 14px;
  color: $light-text-color;
  text-align: center;
  line-height: 200px;
}

/* MAP */

.fm-analytics-map {
  height: 500px;
  margin-top: 20px;
}

@media (max-width: $breakpoint-sm) {

  .fm-analytics-options {
    flex-direction: column;
  }

  .fm-analytics-options .fm-input-container {
    width: 100%;
    margin: 0 0 10px 0;
  }

  .fm-analytics-options-go {
    width: 100%;
  }

}
