.fm-task-view-photo {
  width: 100%;
}

.fm-task-view-content {
  padding: 15px;
}

.fm-task-view-header {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.fm-task-view-status {
  background: red;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.fm-task-view-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-task-view-created {
  flex: 1;
  text-align: right;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

.fm-task-view-rego {
  font-size: 14px;
  color: #fff;
  background: #000;
  font-weight: 600;
  width: 55px;
  text-align: center;
  padding: 2px 0;
  border-radius: 3px 0 0 3px;
  flex: 0 0 55px;
  line-height: 20px;
}

.fm-task-view-notes {
  flex: 1;
  text-align: left;
  font-size: 15px;
  margin-top: 9px;
  padding: 1px;
  line-height: 20px;
}

.fm-task-view-details-link {
  background: $light-background-color;
  margin: 15px 0 10px 0;
  font-size: 14px;
  border-radius: 3px;
  line-height: 30px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-task-view-details-link-left {
  font-weight: 500;
}

.fm-task-view-details-link-right {
  font-weight: 400;
}

@media (prefers-color-scheme: dark) {
  .fm-task-view-details-link {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
}
