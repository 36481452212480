.fm-voucher-view-content {
  margin-top: 12px;
}

.fm-voucher-view-content.fm-voucher-view-content-new {
  margin: 15px;
}

.fm-voucher-view-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px 20px 15px;
}

.fm-voucher-view-row-key {
  font-size: 15px;
}

.fm-voucher-view-row-value {
  font-size: 15px;
  color: $light-text-color;
}

.fm-voucher-view-row-terms {
  padding: 0 0 10px 0;
  line-height: 20px;
  font-size: 14px;
  color: #9C9B9D;
}

.fm-voucher-view-download {
  border: 0;
  font-size: 14px;
  color: #FFFFFF;
  background: $text-color;
  font-weight: 600;
  border-radius: 3px;
  padding: 8px 20px;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 18px;
  margin: 13px 0;
  cursor: pointer;
}

.fm-voucher-view-analytics {
  margin: 0 15px 50px 15px;
}

.fm-voucher-view-analytics-title {
  font-size: 15px;
  font-weight: 600;
}

.fm-voucher-view-analytics-table-header {
  font-size: 14px;
  text-align: left;
  padding: 10px 15px 10px 0;
}

.fm-voucher-view-analytics-table-value {
  font-size: 15px;
  padding: 10px 15px 10px 0;
}

table.fm-voucher-view-analytics-table {
}

@media (max-width: $breakpoint-sm) {
  .fm-voucher-view-download {
    display: none;
  }
}
