.fm-plans {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fm-plans-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
}

.fm-plans-empty {
  color: #999;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.fm-plans-image {
  width: 100%;
  height: 100%;
  flex: 1;
  background-size: contain;
  background-position: 50%;
  background-color: #eee;
  background-repeat: no-repeat;
  border-radius: 3px;
}

.fm-plans-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.fm-plans-user {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.fm-plans-user-name {
  font-size: 18px;
  font-weight: 600;
  margin-left: 15px;
}

.fm-plans-user-email, .fm-plans-user-plan {
  font-size: 16px;
  margin: 10px 0 0 15px;
}

.fm-plans-user-time {
  font-size: 14px;
  margin: 10px 0 0 15px;
  color: #999;
}

.fm-plans-image-approved {
  animation: fm-plans-approved 0.5s ease;
  animation-fill-mode: forwards;
}

.fm-plans-image-invalid {
  animation: fm-plans-invalid 0.5s ease;
  animation-fill-mode: forwards;
}

@keyframes fm-plans-approved {
  0% {
    background-color: #eee;
  }

  10% {
    background-color: #2ecc71;
  }

  100% {
    background-color: #eee;
  }
}

@keyframes fm-plans-invalid {
  0% {
    background-color: #eee;
  }

  10% {
    background-color: #e74c3c;
  }

  100% {
    background-color: #eee;
  }
}

.fm-plans-controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;
}

.fm-plans-controls-invalids {
  display: flex;
  margin: 10px 0;
}

button.fm-plans-controls-invalid {
  flex: 1;
  border-radius: 3px;
  background: #e74c3c;
  color: #fff;
  font-weight: 600;
  line-height: 35px;
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
  height: 35px;
  padding: 0;
  font-size: 12px;
}

button.fm-plans-controls-ignore {
  flex: 1 1;
  border-radius: 3px;
  background: #bdc3c7;
  color: #fff;
  font-weight: 600;
  line-height: 35px;
  margin-left: 15px;
  cursor: pointer;
  height: 35px;
  padding: 0;
  overflow: hidden;
  font-size: 13px;
}

button.fm-plans-controls-approved {
  flex: 1;
  border-radius: 3px;
  background: #2ecc71;
  color: #fff;
  font-weight: 600;
  line-height: 35px;
  margin-left: 15px;
  cursor: pointer;
  height: 35px;
  padding: 0;
  overflow: hidden;
  font-size: 13px;
}

.fm-plans-controls-expiry {
  margin-left: 15px;
}

.fm-plans-controls-expiry-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.fm-plans-controls-expiry-note {
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

.fm-plans-controls-expiry-months {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.fm-plans-controls-expiry-month {
  border-radius: 3px;
  background: $light-background-color;
  line-height: 29px;
  cursor: pointer;
  height: 35px;
  padding: 0;
  overflow: hidden;
  font-size: 13px;
  flex: 0 0 calc(25% - 6px);
  box-sizing: border-box;
  border: 3px solid $light-background-color;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
}

.fm-plans-controls-expiry-month-active {
  border-color: #4caf50;
}

.fm-plans-controls-expiry-years {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
}

.fm-plans-controls-expiry-year {
  border-radius: 3px;
  background: $light-background-color;
  font-weight: 600;
  line-height: 29px;
  cursor: pointer;
  height: 35px;
  padding: 0;
  overflow: hidden;
  font-size: 13px;
  flex: 1;
  box-sizing: border-box;
  border: 3px solid $light-background-color;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
}

@media (max-width: $breakpoint-md) {
  .fm-plans-content {
    flex-direction: column;
  }
  .fm-plans-details {
    flex: 0;
    margin-top: 15px;
    height: auto;
    width: 100%;
  }
  .fm-plans-user-name, .fm-plans-user-email, .fm-plans-user-plan, .fm-plans-user-time, button.fm-plans-controls-approved, button.fm-plans-controls-invalid, button.fm-plans-controls-ignore, .fm-plans-controls-expiry {
    margin-left: 0;
  }
  .fm-plans-controls-expiry-note {
    display: none;
  }
  button.fm-plans-controls-invalid + button.fm-plans-controls-invalid {
    margin-left: 15px;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-plans-image {
    background-color: $light-background-color-dark;
  }
  .fm-plans-controls-expiry-month, .fm-plans-controls-expiry-year {
    background: $light-background-color-dark;
    border-color: $light-background-color-dark;
    color: #fff;
  }
}

.fm-plans-controls-expiry-month-active, .fm-plans-controls-expiry-year-active {
  border-color: #4caf50;
}
