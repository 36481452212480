.fm-report-parking-review {
	display: flex;
	max-height: 550px;
  max-height: calc(100vh - 111px);
}

.fm-report-parking-review-image {
	flex: 1;
	margin: 15px;
	border-radius: 3px;
	background-position: 50%;
	background-size: contain;
	background-color: $light-background-color;
	background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-report-parking-empty {
  font-size: 15px;
}

.fm-report-parking-review-options {
	flex: 1;
	overflow-y: scroll;
	margin: 15px 15px 15px 0;
	padding: 10px 10px 30px 10px;
	border-radius: 3px;
	border: 1px solid $border-color;
}

.fm-report-parking-review-option {
  flex: 1;
  cursor: pointer;
  background-color: $light-background-color;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 8px;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  transition: ease all 0.1s;
}

.fm-report-parking-review-option:hover {
  background-color: $border-color;
}

.fm-report-parking-review-option-shortcut {
  width: 40px;
  height: 60px;
  flex: 0 0 40px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  color: $border-color;
}

.fm-report-parking-review-option-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
}

.fm-report-parking-review-option-icon {
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  color: $border-color-dark;
}

.fm-report-parking-review-option-icon.fm-report-parking-review-option-icon-good {
  color: #4cd137;
}

.fm-report-parking-review-option-icon.fm-report-parking-review-option-icon-bad {
  color: #e84118;
}

.fm-report-parking-review-option-title {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 5px 0;
}

.fm-report-parking-review-option-description {
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  color: $border-color-dark;
  margin-bottom: 6px;
}

/* ------ Animations ------ */

@keyframes report-good {
  0% { background-color: $light-background-color; }
  50% {background-color: #4cd137; }
  100% { background-color: $light-background-color; }
}

@keyframes report-bad {
  0% { background-color: $light-background-color; }
  50% {background-color: #e84118; }
  100% { background-color: $light-background-color; }
}

@keyframes report-indeterminable {
  0% { background-color: $light-background-color; }
  50% {background-color: $border-color-dark; }
  100% { background-color: $light-background-color; }
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-report-parking-review-image, .fm-report-parking-review-option {
    background-color: $light-background-color-dark;
  }
	.fm-report-parking-review-option:hover {
	  background-color: #111;
	}
	.fm-report-parking-review-option-icon {
		color: $border-color;
	}
	.fm-report-parking-review-options {
		border-color: $border-color-dark;
	}
	@keyframes report-good {
	  0% { background-color: $light-background-color-dark; }
	  50% {background-color: #4cd137; }
	  100% { background-color: $light-background-color-dark; }
	}

	@keyframes report-bad {
	  0% { background-color: $light-background-color-dark; }
	  50% {background-color: #e84118; }
	  100% { background-color: $light-background-color-dark; }
	}

	@keyframes report-indeterminable {
	  0% { background-color: $light-background-color-dark; }
	  50% {background-color: $border-color-dark; }
	  100% { background-color: $light-background-color-dark; }
	}
}

/* ------ Sizing ------ */

@media (max-width: $breakpoint-md) {
  .fm-report-parking-review-option-shortcut {
    display: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-report-parking-review {
    max-height: none;
		display: block;
  }
	.fm-report-parking-review-image {
		height: 400px;
	}
	.fm-report-parking-review-options {
    overflow-y: auto;
    border: none;
		padding: 0;
    margin: 15px;
	}
}

.fm-report-parking-review-options-disabled .fm-report-parking-review-option {
	pointer-events: none;
  opacity: 0.5;
}
