.fm-battery-form {
  display: flex;
  padding: 15px;
}

.fm-battery-submit {
  margin-left: 15px;
  border: 0;
  font-size: 16px;
  color: #fff;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 20px;
  transition: all .1s ease;
  display: block;
  font-family: -apple-system,BlinkMacSystemFont,San Francisco,Lato,Segoe UI,Helvetica Neue,sans-serif;
  line-height: 21px;
  cursor: pointer;
}

.fm-battery-repair {
  margin: 15px;
}

.fm-battery-repair-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.fm-battery-repair-details {
  color: $light-text-color;
  font-size: 14px;
  line-height: 24px;
}
