/* PARKING OVERVIEW */

.fm-parking-overview-stats {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 30px 0;
  margin: 5px 0 50px 0;
  border-bottom: 1px solid $border-color;
}

.fm-parking-overview-stat {
  flex: 1;
}

.fm-parking-overview-stat-value {
  font-size: 50px;
  text-align: center;
  font-weight: 500;
}

.fm-parking-overview-stat-label {
  font-size: 14px;
  color: $light-text-color;
  padding: 10px;
  text-align: center;
}

.fm-parking-overview-graph-title {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.fm-parking-overview-counts {
  display: flex;
  justify-content: space-evenly;
}

.fm-parking-overview-graph {
  flex: 1;
  padding: 0 20px;
}

.fm-parking-overview-counts-list {
  flex: 1;
  padding: 0 20px;
}

.fm-parking-overview-counts-item {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  padding: 10px;
  display: block;
}

.fm-parking-overview-counts-item-value {
  text-align: right;
  float: right;
}

.fm-parking-overview-count-title {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 25px;
}

.fm-parking .fm-popup-box .fm-input-container {
  margin: 10px 20px;
}

/* PARKING REVIEW */

.fm-parking-review-image {
  height: 70vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eee;
}

.fm-parking-review-controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px -10px;
}

.fm-parking-review-control {
  flex: 1;
  background: #95a5a6;
  margin: 10px;
  font-size: 20px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;

  &:hover {
    background: #7f8c8d;
    cursor: pointer;
  }
}

.fm-parking-offence-control-bad {
  background: #e74c3c;

  &:hover {
    background: #c0392b;
  }
}

.fm-parking-offence-control-good {
  background: #2ecc71;

  &:hover {
    background: #27ae60;
  }
}

.fm-parking-review-options {
  height: 100%;
  width: 100%;
  background: rgba(220,220,220,0.8);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 20px;
}

.fm-parking-review-option {
  min-width: 50%;
  background: #fff;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  line-height: 30px;

  &:hover {
    background: #eee;
    cursor: pointer;

    .fm-parking-review-option-number {
      background: #ddd;
    }
  }
}

.fm-parking-review-open {
  background: #ececec;
  margin: 10px 0;
  padding: 12px 20px;
  border-radius: 5px;
  display: block;
  text-align: center;
}

.fm-parking-review {
  display: flex;
  justify-content: space-evenly;
  padding: 8px;
}

.fm-parking-review-option-number {
  font-weight: 600;
  color: #333;
  margin-right: 10px;
  background: #eee;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
}

/* PARKING OFFENCE */

.fm-parking-offence {
  padding-bottom: 50px;
}

.fm-parking-offence-content {
  display: flex;
}

.fm-parking-offence-photo {
  margin: 10px 25px 10px 0;
  border-radius: 5px;
  max-height: 450px;
  max-width: 400px;
}

.fm-parking-offence-content .fm-map {
  flex: 1;
  height: auto;
  margin: 10px 0;
}

.fm-parking-offence-detail-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  padding: 5px 0;
}

.fm-parking-offence-details {
  padding: 15px 0;
}

.fm-parking-offence-detail-label {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 0;
}

.fm-parking-offence-history-label {
  background: #f8f7f9;
  padding: 10px 18px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  margin-right: 15px;
}

.fm-parking-offence-history {
  margin: 10px 0;
}

.fm-parking-offence-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-parking-offence-control {
  background: #f8f7f9;
  padding: 10px 18px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
  color: #fff;

  &:hover {
    opacity: 0.9;
  }
}

.fm-parking-offence-control-fine {
  background: #E74C3C;
}

.fm-parking-offence-control-view {
  background: #f8f7f9;
  color: #222;
}

.fm-parking-offence-control-warning {
  background: #E67E22;
}

.fm-parking-offence-control-dismiss {
  background: #6ab04c
}
