.fm-tab-bar {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid $border-color;
}

.fm-tab-bar-header {
  display: none;
}

.fm-tab-bar-logo {
  display: block;
  background: url('https://storage.googleapis.com/flamingo-static/images/admin/wordmark-black.svg') 20px 60% no-repeat;
  height: 60px;
  background-size: 100px;
  font-size: 0;
}

.fm-tab-bar-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.fm-tab-bar-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  font-size: 13px;
  padding: 5px 0;
  position: relative;
}

.fm-tab-bar-item .fa {
  font-size: 15px;
  margin-bottom: 5px;
}

.fm-tab-bar-item-badge {
  position: absolute;
  top: 0;
  right: 5px;
  height: 20px;
  width: 20px;
  background: #eb3b5a;
  line-height: 20px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  border-radius: 10px;
}

.fm-tab-bar-item-desktop {
  display: none;
}

.fm-tab-bar-profile {
  width: 100%;
  height: 70px;
  display: none;
  flex-direction: row;
  border-top: 1px solid #E8E7EA;
  padding: 15px 20px;
  background: #fff;
  flex: 0 0 70px;
  align-items: center;
}

.fm-tab-bar-profile-avatar {
  height: 15px;
  width: 15px;
  background: #ff206e;
  border-radius: 100%;
  margin-right: 15px;
}

.fm-tab-bar-profile-name {
  font-size: 15px;
  line-height: 20px;
}

.fm-tab-bar-profile-region {
  font-size: 14px;
  color: #999;
  line-height: 19px;
}

@media (min-width: $breakpoint-sm) {
  .fm-tab-bar-item-badge {
    right: 10px;
  }
}

@media (min-width: $breakpoint-md) {
  .fm-tab-bar-item-badge {
    right: 30px;
  }
}

@media (min-width: $breakpoint-lg) {
  .fm-tab-bar {
    width: 250px;
    height: 100%;
    border-top: none;
    flex-direction: column;
    border-right: 1px solid $border-color;
  }

  .fm-tab-bar-items {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
    justify-content: flex-start;
    overflow-y: scroll;
  }

  .fm-tab-bar-header {
    display: block;
    height: 61px;
    width: 100%;
    border-bottom: 1px solid #E8E7EA;
    background: #fff;
  }

  .fm-tab-bar-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    text-align: left;
    font-size: 15px;
    padding: 20px 10px 20px 20px;
  }

  .fm-tab-bar-item-desktop, .fm-tab-bar-profile {
    display: flex;
  }

  .fm-tab-bar-item-mobile {
    display: none;
  }

  .fm-tab-bar-item-badge {
    right: 15px;
    top: 19px;
  }

  .fm-tab-bar-item .fa {
    margin-bottom: 0;
    margin-right: 15px;
    width: 15px;
    text-align: center;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-tab-bar {
    border-top-color: $border-color-dark;
    border-right-color: $border-color-dark;
  }
  .fm-tab-bar-logo {
    background: url('https://storage.googleapis.com/flamingo-static/images/admin/wordmark-white.svg') 20px 60% no-repeat;
    background-size: 100px;
  }
  .fm-tab-bar-profile {
    border-top-color: $border-color-dark;
    background: $background-color-dark;
  }
  .fm-tab-bar-header {
    border-bottom-color: $border-color-dark;
    background: $background-color-dark;
  }
}
