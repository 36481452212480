.fm-document-version-content {
  display: flex;
  margin: 20px;
  gap: 25px;
}

.fm-document-version-details {
  flex: 1;
}

.fm-document-version-markdown {
  flex: 3;
  background: $light-background-color;
  border-radius: 5px;
  white-space: pre-wrap;
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

.fm-document-version-markdown-textarea {
  height: 80vh;
  width: 100%;
  border: none;
  background: transparent;
  padding: 20px;
  font-size: 12px;
  line-height: 18px;
}

.fm-document-version-markdown-textarea:disabled {
  color: $light-text-color;
}

.fm-document-version-details-row-value {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin: 4px 0 0;
}

.fm-document-version-details-row-label {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.fm-document-version-details-row {
  margin-bottom: 15px;
}

.fm-document-version-details-buttons {
  display: flex;
  gap: 15px;
}

.fm-document-version-details-button {
  background: $text-color;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  transition: all .1s ease;
  padding: 0 20px;
  height: 44px;
  margin: 15px 0;
}

.fm-document-version-details-button-destructive {
  background: rgba(231, 76, 60, 1.0);
}

.fm-document-version-details-notes {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin: 4px 0 0;
  line-height: 20px;
}
