.fm-button {
  border: 0;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding: 13px 1px;
  line-height: 20px;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  margin: 30px 0;
  border-radius: 3px;
  background: $secondary;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  &:focus {
    opacity: 1;
    color: #eee;
  }
}
