.fm-performance-timeliness {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.fm-performance-timeliness-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 9px;
}

.fm-performance-timeliness-options .fm-input-container {
  margin: 0;
  flex: 1;
  margin: 0 6px;
}

.fm-performance-timeliness-options-go {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  color: #222222;
  background: #F8F7F9;
  border-radius: 5px;
  padding: 10px 15px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0 6px;

  &:hover {
    background: #F4F3F4;
  }
}

.fm-performance-timeliness-event-row-description {
	flex: 1;
	line-height: 20px;
	padding: 0 12px;
	font-size: 15px;
}

.fm-performance-timeliness-event-row-type {
	text-transform: uppercase;
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	padding: 4px 5px;
	border-radius: 3px;
	display: inline-block;
	cursor: pointer;
	background: #000;
	width: 110px;
	text-align: center;
}

.fm-performance-timeliness-row {
	padding: 0 15px;
}

.fm-performance-timeliness-row-name {
	font-size: 16px;
	font-weight: 600;
	line-height: 25px;
  margin-top: 30px;
}

.fm-performance-timeliness-event-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;
  padding: 5px 0 15px 0;
  border-bottom: 1px dashed #eee;
}

.fm-performance-timeliness-events {
	margin: 10px 0 20px 0;
}

.fm-performance-timeliness-event-row-time {
	font-size: 15px;
	color: $light-text-color;
  border-right: 4px solid;
  padding-right: 5px;
  line-height: 22px;
}

.fm-performance-timeliness-event-row-compare {
  background: #e84118;
  color: #fff;
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 0 5px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 10px;
}
