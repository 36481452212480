.fm-call {
  padding: 15px;
}

.fm-call-description {
  margin: 5px 0;
  font-size: 16px;
}

.fm-call-link {
  border: 0;
  font-size: 16px;
  color: $text-color;
  background: $light-background-color;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 20px 0 13px 0;
  text-align: center;
  cursor: pointer;
}

.fm-call-button {
  border: 0;
  font-size: 16px;
  color: #fff;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 13px 0;
  cursor: pointer;
  text-align: center;
}
