.fm-task-route-new-box {
  width: 100vw;
  max-width: 100%;
  height: 100vw;
  min-height: 325px;
  max-height: 400px;
  background: #222222;
}

.fm-task-route-new-selected {
  background: #222222;
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  min-height: 64px;
}

.fm-task-route-new-note {
  line-height: 38px;
  color: #ccc;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  padding: 5px;
}

.fm-task-route-new-selected-vehicle {
  background: #f8f7f9;
  margin: 7px 5px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  height: 34px;
}

.fm-task-route-new-selected-vehicle-status {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 6px;
}

.fm-task-route-new-selected-vehicle-rego {
  font-size: 16px;
  font-weight: 600;
}

.fm-task-route-new-options {
  padding: 13px;
}

.fm-task-route-new-map {
  height: 100%;
}

.fm-task-route-new-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #ff206e;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 13px 0;
  cursor: pointer;
}
