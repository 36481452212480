button.fm-regions-button {
  border: 0;
  background: #fff;
  display: block;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  margin: 15px 0;
  opacity: 1;
  width: 100%;
  text-align: left;
}

button.fm-regions-button-loading {
  opacity: 0.5;
}
