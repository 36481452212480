.fm-ticket-active-item {
  margin: 20px 15px 40px 15px;
  display: block;
}

.fm-ticket-active-item-vehicle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-task-active-heading {
  background: $light-background-color;
  color: $light-text-color;
  margin: 15px 15px 25px 15px;
  font-size: 14px;
  border-radius: 3px;
  padding: 10px 12px;
  line-height: 20px;
}

.fm-ticket-active-item-vehicle-label {
  background: #000;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 28px;
  flex: 0 0 74px;
  width: 74px;
  padding: 2px 8px;
  border-radius: 3px;
}

.fm-ticket-active-item-vehicle-status {
  width: 12px;
  height: 12px;
  flex: 0 0 12px;
  border-radius: 6px;
  margin-right: 6px;
}

.fm-ticket-active-item-vehicle-rego {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
  flex: 1;
  text-align: center;
}

.fm-ticket-active-item-ticket-type {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: rebeccapurple;
  padding: 2px 7px;
  border-radius: 3px 0 0 3px;
  line-height: 20px;
}

.fm-ticket-active-item-ticket-source {
  font-size: 13px;
  font-weight: 600;
  color: #666;
  background: #eee;
  padding: 2px 7px;
  border-radius: 0 3px 3px 0;
  line-height: 20px;
}

.fm-ticket-active-item-ticket-header {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.fm-ticket-active-item-ticket-created {
  flex: 1;
  text-align: right;
  padding: 0 0 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

.fm-ticket-active-item-ticket-content {
  display: flex;
  align-items: center;
  margin-top: 9px;
}

.fm-ticket-active-item-ticket-details {
  flex: 1;
  text-align: left;
  font-size: 15px;
  padding: 1px;
  line-height: 20px;
  display: block;
}

.fm-ticket-active-item-ticket-details .fa.fa-images {
  color: #666;
  margin-left: 8px;
}

.fm-ticket-active-item-ticket-resolve {
  height: 22px;
  width: 22px;
  background: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-align: center;
  color: #DDD;

  &:hover {
    color: #000;
  }
}

.fm-ticket-active-item-vehicle-button {
  font-size: 14px;
  color: #666;
  background: #eee;
  font-weight: 400;
  text-align: center;
  padding: 2px 8px 2px 10px;
  border-radius: 3px;
  line-height: 22px;
}

.fm-ticket-active-item-vehicle-button .fa {
  color: #999;
  margin-left: 6px;
  line-height: 20px;
  font-size: 13px;
}

.fm-ticket-active-item-ticket-img {
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
  margin: 15px 0;
}

.fm-ticket-active-item-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin: 15px 0 0 0;
  padding: 0 0;
  overflow-x: scroll;
}

.fm-ticket-active-item-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $light-background-color;
  height: 30px;
  border-right: 1px solid $border-color;
  cursor: pointer;
  flex-shrink: 0;
}

.fm-ticket-active-item-action-label {
  font-size: 14px;
  color: #666;
  font-weight: 400;
  text-align: center;
  padding: 4px 10px 4px 10px;
  border-radius: 3px;
  line-height: 22px;
}

.fm-ticket-active-item-action .fa {
  color: #999;
  margin: 0 0 0 10px;
  line-height: 20px;
  font-size: 13px;
}

.fm-ticket-active-item-action:first-child {
  border-radius: 3px 0 0 3px;
}

.fm-ticket-active-item-action:last-child {
  border-radius: 0 3px 3px 0;
  border-right: none;
}

.fm-ticket-active-item-action:last-child .fa {
  margin: 0 10px 0 0;
}

.fm-task-active-empty {
  font-size: 14px;
  color: $light-text-color;
  margin: 100px 0 5px 0;
  text-align: center;
}

@media (max-width: $breakpoint-sm) {
  .fm-ticket-active-item-action-large {
    display: none;
  }
  .fm-ticket-active-item-action:last-child .fa {
    margin: 0 10px 0 10px;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-ticket-active-item-vehicle-button {
    background: $light-background-color-dark;
  }
  .fm-ticket-active-item-ticket-resolve {
    color: $light-background-color-dark;
  }
  .fm-task-active-heading, .fm-ticket-active-item-action {
    background: $light-background-color-dark;
  }
  .fm-ticket-active-item-action-label {
    color: $light-text-color;
  }
  .fm-ticket-active-item-action {
    border-right-color: $border-color-dark;
  }
}
