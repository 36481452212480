.fm-zone-map {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-zone-map-main {
  flex: 1 1;
  display: flex;
}

.fm-zone-map-holder {
  flex: 1 1;
  position: relative;
}

.fm-zone-map-info {
  width: 300px;
  flex: 0 0 300px;
  border-left: 1px solid $border-color;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.fm-zone-map-info-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.fm-zone-map-info-value {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}

.fm-zone-map-info-key {
  font-weight: 600;
}

.fm-zone-map-info-type {
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  font-weight: 800;
  margin: 4px 0;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
}

.fm-zone-map-info-message {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  color: $light-text-color;
  font-style: italic;
}

.fm-zone-map-info-photo {
  width: 100%;
  border-radius: 3px;
  margin: 10px 0;
}

.fm-zone-map-info-content {
  flex: 1;
}

.fm-zone-map-info-action {
  border: 0;
  font-size: 16px;
  color: #222;
  background: #F8F7F9;
  border-radius: 3px;
  padding: 10px 12px;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  text-align: center;
}

.fm-zone-map-info-action + .fm-zone-map-info-action {
  margin-left: 10px;
}

.fm-zone-map-info-actions {
  display: flex;
  flex-direction: row;
}

.fm-zone-map-filters {
  width: 100%;
  height: 47px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $border-color;
  background: #fff;
  z-index: 9999;
  flex: 0 0 105px;
}

.fm-zone-map-filters-types {
  display: flex;
  flex-direction: row;
  height: 47px;
  padding: 8px 13px 6px 13px;
  overflow-x: scroll;
  white-space: nowrap;
}

.fm-zone-map-filters-type {
  display: inline-block;
  color: #fff;
  margin: 5px;
  border-radius: 2px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  padding: 3px 6px;
}

.fm-zone-map-filters-options {
  display: flex;
  flex-direction: row;
  padding: 2px 13px 8px 13px;
  height: 54px;
}

.fm-zone-map-filters-select {
  margin-left: 12px;
}

.fm-zone-map-filters-input {
  flex: 2;
}

.fm-zone-map-filters-select {
  flex: 1;
}

@media (prefers-color-scheme: dark) {
  .fm-zone-map-filters {
    background-color: $background-color-dark;
    border-top-color: $border-color-dark;
  }
}
