/* ------ Colours ------ */

$support-note-background: #fadb9e;
$support-note-color: #54421e;
$support-message-background: #1c77fc;

/* ------ Tags ------ */

.fm-support-ticket-tag {
  background: $light-background-color;
  display: flex;
  padding: 5px 10px;
  border-radius: 3px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fm-support-ticket-tag-actionable {
  cursor: pointer;
}

.fm-support-ticket-tags {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin: 20px 15px 0 15px;
}

.fm-support-ticket-tag-status {
  background: #fff;
  border-radius: 7px;
  display: inline-block;
  height: 14px;
  margin: 2px 10px 2px 0;
  vertical-align: bottom;
  width: 14px;
  background: #d5d5d5;
}

.fm-support-ticket-tag-label {
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.fm-support-ticket-created {
  flex: 1 1;
  text-align: right;
  font-size: 14px;
  line-height: 28px;
  height: 28px;
  white-space: nowrap;
  padding: 0 5px 0 0;
  color: $light-text-color;
}

/* ------ Relations ------ */

.fm-support-ticket-relations {
  display: flex;
  margin: 10px 20px 10px 20px;
}

.fm-support-ticket-relation {
  flex: 1;
  margin: 3px 0;
}

.fm-support-ticket-relation-row {
  height: 32px;
}

.fm-support-ticket-relation-label {
  font-weight: 600;
  padding: 5px 10px 5px 0;
  line-height: 18px;
  font-size: 14px;
  text-transform: capitalize;
}

.fm-support-ticket-relation-value {
  padding: 5px 10px 5px 0;
  line-height: 18px;
  font-size: 14px;
  text-transform: capitalize;
}

.fm-support-ticket-relation-dot {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  margin: 2px 12px 2px 0;
  background: $light-background-color;
  display: inline-block;
  vertical-align: bottom;
}

@media (max-width: $breakpoint-md) {
  .fm-support-ticket-relations {
    flex-direction: column;
  }
  .fm-support-ticket-relation-table {
    width: 100%;
    table-layout: fixed;
    max-width: 100%;
  }
  .fm-support-ticket-relation-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* ------ Notes ------ */

.fm-support-ticket-notes {
  font-size: 14px;
  color: $light-text-color;
  font-style: italic;
  margin: 0 20px 25px 20px;
  line-height: 20px;
}

.fm-support-ticket-notes-label {
  font-weight: 600;
}

/* ------ Items ------ */

.fm-support-ticket-items {
  border-top: 1px solid $border-color;
  margin: 15px 0 0 0;
}

.fm-support-ticket-items-list {
  width: 100%;
  max-width: 850px;
  margin: 30px auto;
  padding: 0 20px;
}

/* ------ Item ------ */

.fm-support-ticket-item-content {
  background: $light-background-color;
  border-radius: 3px;
  line-height: 25px;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 15px;
  white-space: pre-line;
  word-break: break-word;
  font-size: 15px;
}

.fm-support-ticket-item-creator {
  color: $light-text-color;
  flex: 1 1;
  font-size: 14px;
  font-style: italic;
  line-height: 30px;
  margin: 0 2px;
  text-align: right;
}

.fm-support-ticket-item-content-link {
  text-decoration: underline;
}

.fm-support-ticket-item-note, .fm-support-ticket-item-internal {
  margin: 20px 0 20px 20%;
}

.fm-support-ticket-item-external {
  margin: 20px 20% 20px 0;
}

.fm-support-ticket-item-external .fm-support-ticket-item-creator {
  text-align: left;
}

.fm-support-ticket-item-note .fm-support-ticket-item-content {
  background: $support-note-background;
  color: $support-note-color;
}

.fm-support-ticket-item-internal .fm-support-ticket-item-content {
  background: $support-message-background;
  color: #fff;
}

.fm-support-ticket-item-content-img {
  max-width: 100%;
}

/* ------ Responder ------ */

.fm-support-ticket-responder {
  width: 100%;
  max-width: 850px;
  margin: 30px auto 80px auto;
  padding: 0 20px;
}

.fm-support-ticket-responder-composer {
  background: $light-background-color;
  border-radius: 3px;
  resize: none;
}

.fm-support-ticket-responder-composer-textarea {
  min-height: 250px;
  padding: 10px 15px;
  font-size: 15px;
}

.fm-support-ticket-responder-composer-textarea:disabled {
  color: $light-text-color;
}

.fm-support-ticket-responder-composer-footer {
  border-top: 1px solid $border-color;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-support-ticket-responder-composer .fm-loading-line {
  background: $border-color;
  height: 1px;
}

.fm-loading-line + .fm-support-ticket-responder-composer-footer {
  border-top-width: 0;
}

.fm-support-ticket-responder-composer-template {
  height: 40px;
  position: relative;
  flex: 1;
}

.fm-support-ticket-responder-composer-template-icon {
  height: 40px;
  width: 40px;
  font-size: 20px;
  color: $light-text-color;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.fm-support-ticket-responder-composer-template-name {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: $light-text-color;
  line-height: 20px;
  padding: 10px 10px 10px 0;
}

.fm-support-ticket-responder-composer-template-select {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 0;
}

.fm-support-ticket-responder-composer-chatgpt {
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  -webkit-appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fm-support-ticket-responder-composer-chatgpt-image {
  opacity: 0.4;
  height: 20px;
  width: 20px;
}

.fm-support-ticket-responder-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
}

.fm-support-ticket-responder-actions-side {
  display: flex;
  gap: 15px;
}

.fm-support-ticket-responder-action {
  height: 45px;
  min-width: 45px;
  border-radius: 3px;
  background: $light-background-color;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  letter-spacing: 0.1px;
  cursor: pointer;
}

.fm-support-ticket-responder-action-note {
  background: $support-note-background;
  color: $support-note-color;
}

.fm-support-ticket-responder-action-send {
  background: $text-color;
  color: #fff;
}

.fm-support-ticket-responder-action-ressend {
  background: $support-message-background;
  color: #fff;
}

.fm-support-ticket-responder-action:disabled {
  cursor: wait;
}

.fm-support-ticket-responder-action-ressend:disabled, .fm-support-ticket-responder-action-send:disabled {
  color: rgba(255, 255, 255, 0.5);
}

.fm-support-ticket-responder-action-note:disabled {
  color: rgba(84, 66, 30, 0.5);
}

.fm-support-ticket-responder-action-resolve:disabled {
  color: $light-text-color;
}

@media (max-width: $breakpoint-xs) {
  .fm-support-ticket-responder-actions {
    flex-direction: column;
    gap: 15px;
  }
  .fm-support-ticket-responder-action {
    flex: 1;
  }
}

/* ------ Pop ------ */

.fm-support-ticket-pop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fm-show-popup 0.2s ease;
  animation-fill-mode: forwards;
}

.fm-support-ticket-pop-box {
  background: #fff;
  border-radius: 3px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 90vw;
  width: 500px;
}

.fm-support-ticket-pop-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

.fm-support-ticket-pop-header {
  border-bottom: 1px solid $border-color;
  padding: 15px 20px;
}

.fm-support-ticket-pop-description {
  font-size: 14px;
  line-height: 20px;
  margin-top: 3px;
}

.fm-support-ticket-pop-footer {
  border-top: 1px solid $border-color;
  display: flex;
  flex-direction: column;
}

.fm-support-ticket-pop-options {
  overflow: scroll;
}

.fm-support-ticket-pop-option {
  background: $light-background-color;
  margin: 15px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
}

.fm-support-ticket-pop-option-icon {
  flex: 0 0 55px;
  text-align: center;
  font-size: 16px;
}

.fm-support-ticket-pop-option-details {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
}

.fm-support-ticket-pop-option-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.fm-support-ticket-pop-option-description {
  font-size: 12px;
  line-height: 18px;
  margin-top: 1px;
}

.fm-support-ticket-pop-cancel {
  background: $light-background-color;
  margin: 15px 20px;
  text-align: center;
  display: block;
  padding: 10px 0;
  border-radius: 3px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-weight: 600;
}

.fm-support-ticket-pop-destructive {
  background: $light-background-color;
  margin: 15px 20px 0 20px;
  text-align: center;
  display: block;
  padding: 10px 0;
  border-radius: 3px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-weight: 600;
  color: #e74c3c;
}

.fm-support-ticket-pop-cancel:hover, .fm-support-ticket-pop-option:hover {
  background: $border-color;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-support-ticket-responder-composer, .fm-support-ticket-item-content, .fm-support-ticket-tag, .fm-support-ticket-pop-option {
    background: $light-background-color-dark;
  }
  .fm-support-ticket-pop-box {
    background: $background-color-dark;
  }
  .fm-support-ticket-item-note .fm-support-ticket-item-content, .fm-support-ticket-responder-action-note {
    background: $support-note-color;
    color: $support-note-background;
  }
  .fm-support-ticket-responder-composer-footer, .fm-support-ticket-items, .fm-support-ticket-pop-footer {
    border-top-color: $border-color-dark;
  }
  .fm-support-ticket-pop-header {
    border-bottom-color: $border-color-dark;
  }
  .fm-support-ticket-responder-composer .fm-loading-line {
    background: $border-color-dark;
  }
  .fm-support-ticket-responder-action-resolve, .fm-support-ticket-pop-cancel {
    background: $light-background-color-dark;
    color: $text-color-dark;
  }
  .fm-support-ticket-pop-cancel:hover, .fm-support-ticket-pop-option:hover {
    background: $border-color-dark;
  }
}
