
/* ------ Content ------ */

.fm-trip-section-header {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-trip-section-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
}

.fm-trip-section-button {
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  border-radius: 3px;
  margin: 1px 15px 1px 0;
  background: #f8f7f9;
  cursor: pointer;
}

/* ------ Map ------ */

.fm-trip-map {
  height: 250px;
  background: #eee;
}

/* ------ Status ------ */

.fm-trip-status {
  padding: 17px 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.fm-trip-status-item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fm-trip-status-item-icon {
  border-radius: 50%;
  background: $light-background-color;
  padding: 12px;
  width: 44px;
  height: 44px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.fm-trip-status-item-icon-pink {
  color: $primary;
}

.fm-trip-status-item-text {
  font-size: 14px;
  line-height: 22px;
  margin: 7px 0 5px 0;
  text-align: center;
}

/* ------ Duration ------ */

.fm-trip-duration {
  padding: 0 15px;
  margin-bottom: 30px;
}

.fm-trip-duration-inner {
  background: $light-background-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 35px;
  border-radius: 5px;
}

.fm-trip-duration-line {
  position: absolute;
  height: 1px;
  background: $border-color;
  top: 17px;
  right: 0;
  left: 0;
}

.fm-trip-duration-time {
  font-size: 14px;
  line-height: 35px;
  padding: 0 15px;
  background: $light-background-color;
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-trip-duration-value {
  font-size: 14px;
  line-height: 35px;
  padding: 0 15px;
  font-weight: 600;
  background: $light-background-color;
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: $breakpoint-sm) {
  .fm-trip-duration-line {
    display: none;
  }
  .fm-trip-duration-value {
    padding: 0;
  }
}

/* ------ Payment ------ */

.fm-trip-payment {
  padding: 5px 15px 15px 15px;
}

.fm-trip-payment-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 15px 0;
}

.fm-trip-payment-label {
  font-size: 15px;
  line-height: 20px;
}

.fm-trip-payment-value {
  font-size: 15px;
  line-height: 20px;
  color: #999;
}

.fm-trip-payment-status {
  display: flex;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
}

.fm-trip-payment-status-value {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #639;
  padding: 2px 7px;
  line-height: 20px;
}

.fm-trip-payment-status-button {
  font-size: 13px;
  color: #666;
  background: #eee;
  font-weight: 400;
  text-align: center;
  padding: 1px 8px 1px 10px;
  line-height: 22px;
  cursor: pointer;
}

.fm-trip-payment-status-button .fa {
  color: #999;
  margin-left: 6px;
  line-height: 20px;
  font-size: 13px;
}

/* ------ Review ------ */

.fm-trip-review {
  padding: 10px 15px 25px 15px;
}

.fm-trip-review-text {
  font-size: 15px;
  line-height: 20px;
}

/* ------ Tabs ------ */

.fm-trip-tabs {
  display: flex;
  overflow-x: scroll;
}

.fm-trip-tab {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
  margin: 12px 0 10px 0;
  opacity: 0.4;
  cursor: pointer;
  white-space: nowrap;
}

.fm-trip-tabs-loading {
  height: 2px;
}

.fm-trip-tab-active {
  opacity: 1.0;
}

.fm-trip-tab-content {
  padding: 7px 15px 50px 15px;
  min-height: 500px;
}

.fm-trip-tab-content-empty {
  text-align: center;
  font-size: 14px;
  color: #999;
  margin: 50px;
}

.fm-trip-tab-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.fm-trip-tab-row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-trip-tab-row-title {
  font-size: 15px;
  padding: 5px 10px 5px 0;
  line-height: 20px;
}

.fm-trip-tab-row-detail {
  color: #999;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-trip-tab-photo-img {
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
}

.fm-trip-tab-photo-empty {
  text-align: center;
  font-size: 14px;
  color: #999;
  margin: 50px;
}

.fm-trip-tab-row-tag {
  height: 24px;
  padding: 0;
  width: 105px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-trip-status-item-icon, .fm-trip-duration-inner, .fm-trip-duration-time, .fm-trip-duration-value, .fm-trip-section-button {
    background-color: $light-background-color-dark;
    color: $text-color-dark;
  }
  .fm-trip-duration-line {
    background-color: $border-color-dark;
  }
}
