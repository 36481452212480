.fm-vehicle-theft {
  margin-bottom: 50px;
}

.fm-vehicle-theft-map {
  height: 300px;
}

.fm-vehicle-theft-summary {
  margin: 15px 15px 30px 15px;
}

.fm-vehicle-theft-summary-row {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.fm-vehicle-theft-summary-text {
  flex: 1;
  text-align: left;
  font-size: 15px;
  padding: 1px;
  line-height: 20px;
}

.fm-vehicle-theft-summary-date {
  flex: 1;
  text-align: right;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.fm-vehicle-theft-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
}

.fm-vehicle-theft-items {
  margin-bottom: 25px;
}

.fm-vehicle-theft-item {
  margin: 15px;
  display: block;
}

.fm-vehicle-theft-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-theft-item-tag {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #639;
  padding: 2px 7px;
  border-radius: 3px;
  line-height: 20px;
}

.fm-vehicle-theft-item-date {
  flex: 1 1;
  text-align: right;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.fm-vehicle-theft-item-text {
  flex: 1;
  text-align: left;
  font-size: 14px;
  margin-top: 9px;
  padding: 1px;
  line-height: 20px;
}
