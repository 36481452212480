.fm-performance-user {
  display: flex;
  flex-direction: column;
}

.fm-performance-user-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 9px;
}

.fm-performance-user-options .fm-input-container {
  margin: 0;
  flex: 1;
  margin: 0 6px;
}

.fm-performance-user-options-go {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  color: #222222;
  background: #F8F7F9;
  border-radius: 5px;
  padding: 10px 15px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0 6px;

  &:hover {
    background: #F4F3F4;
  }
}

.fm-performance-user-list {
  flex: 1;
  padding: 0 15px 50px 15px;
  overflow-x: scroll;
}

.fm-performance-user-row {
  display: flex;
  align-items: center;
  margin: 10px 0 25px 0;
}

.fm-performance-user-row-registration {
  background: #111111;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 0;
  width: 62px;
  border-radius: 3px;
  text-align: center;
}

.fm-performance-user-row-date {
  flex: 1;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
}

.fm-performance-user-row-type {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
}

.fm-performance-user-map {
  margin: 0 15px 15px 15px;
  border-radius: 3px;
  overflow: hidden;
  height: 350px;
}

.fm-performance-user-row-activity-type {
  font-size: 12px;
  font-weight: 600;
  margin: 0 15px;
  color: #fff;
  background-color: grey;
  padding: 2px 5px;
  border-radius: 3px;
}

.fm-performance-user-row-activity-date {
  flex: 1 1;
  padding: 0 10px;
  font-size: 14px;
  color: #999;
  line-height: 20px;
  text-align: right;
}
