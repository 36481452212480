.fm-side-bar {
  width: 300px;
  border-right: 1px solid $border-color;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fm-side-bar-top {
  border-bottom: 1px solid $border-color;
}

.fm-side-bar-top-logo {
  display: block;
  background: url('https://storage.googleapis.com/flamingo-static/images/admin/wordmark-pink.svg') 0% 65% no-repeat;
  height: 40px;
  background-size: 100px;
  margin: 15px 20px;
  font-size: 0;
  transition: ease all 0.15s;

  &:hover {
    cursor: pointer;
  }
}

.fm-side-bar-navigation {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  overflow-y: scroll;
}

.fm-side-bar-link {
  line-height: 20px;
  padding: 17px 10px 17px 30px;
  font-size: 15px;

  .fa {
    margin-right: 20px;
    color: $light-text-color;
    width: 20px;
    text-align: center;
  }

  &:hover .fa {
    color: $text-color;
  }
}

.fm-side-bar-profile {
  min-height: 90px;
  border-top: 1px solid $border-color;
  overflow: hidden;
}

.fm-side-bar-profile-display {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;

  &:hover {
    cursor: pointer;
    background: $light-background-color;
  }
}

.fm-side-bar-profile-content {
  display: flex;
  flex-direction: column;
}

.fm-side-bar-profile-avatar {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: #ffbad2;
}

.fm-side-bar-profile-details {
  padding-left: 15px;
  flex: 1;
}

.fm-side-bar-profile-name {
  font-size: 16px;
  line-height: 22px;
}

.fm-side-bar-profile-region {
  color: #999;
  font-size: 14px;
  line-height: 20px;
}

.fm-side-bar-profile-links {
  max-height: 0;
  transition: ease max-height 0.5s, ease padding 0.4s;
  display: flex;
  flex-direction: column;
  padding: 0;
  background: $light-background-color;
}

.fm-side-bar-profile-links-open {
  max-height: 200px;
  padding: 10px 0;
}

.fm-side-bar-profile-menu-link {
  line-height: 20px;
  margin: 10px 10px 10px 38px;
  font-size: 14px;

  .fa {
    margin-right: 33px;
    color: $light-text-color;
  }

  &:hover {
    color: $text-color;
    .fa {
      color: $text-color;
    }
  }
}

.fm-side-bar-profile-chev .fa {
  color: $light-text-color;
}

@media (max-width: $breakpoint-lg) {
  .fm-side-bar {
    width: 100%;
    height: 71px;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    transition: ease height 0.3s;
    display: block;
    z-index: 9999;
  }

  .fm-side-bar-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .fm-side-bar-top-logo {
    display: inline-block;
    background: url(https://storage.googleapis.com/flamingo-static/images/admin/logo-black.svg) 50% 50% no-repeat;
    height: 40px;
    background-size: 20px;
    width: 30px;
    margin: 15px 20px;
  }

  .fm-side-bar-top-menu {
    display: inline-block;
    height: 40px;
    width: 40px;
    background: url('https://storage.googleapis.com/flamingo-static/images/admin/menu.png') 50% 50% no-repeat;
    background-size: 30px;
    margin: 15px;
    cursor: pointer;
  }

  .fm-side-bar-open .fm-side-bar-top-menu {
    background: url('https://storage.googleapis.com/flamingo-static/images/admin/close.png') 50% 50% no-repeat;
    background-size: 30px;
  }

  .fm-side-bar-open {
    height: 100%;
    bottom: 0;
    display: flex;
  }

}
