/* ------ Header ------ */

.fm-helmet-lock-header {
  margin: 22px 15px;
}

.fm-helmet-lock-identifier {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

.fm-helmet-lock-updated {
  color: #9c9b9d;
  font-size: 14px;
  margin-top: 8px;
}

.fm-helmet-lock-reminder {
  padding: 0 15px 10px 15px;
}

.fm-helmet-lock-reminder-text {
  font-size: 13px;
  margin: 7px 0 5px 0;
  background: #e74c3c;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  line-height: 20px;
  padding: 7px 10px;
}

/* ------ Controls ------ */

.fm-helmet-lock-controls {
  padding: 0 15px 10px 15px;
}

.fm-helmet-lock-controls-button {
  border: 0;
  cursor: pointer;
  display: block;
  background: #f8f7f9;
  padding: 10px 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 4px 0 8px 0;
  height: 40px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  text-align: center;
  flex: 1 1;
}

.fm-helmet-lock-controls-button + .fm-helmet-lock-controls-button {
  margin-left: 10px;
}

.fm-helmet-lock-controls-group {
  display: flex;
}

/* ------ Status ------ */

.fm-helmet-lock-status {
  margin-top: 22px;
  padding: 17px 0;
  display: flex;
  flex-wrap: wrap;
}

.fm-helmet-lock-status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  flex: 1;
}

.fm-helmet-lock-status-item-icon {
  border-radius: 50%;
  background: $light-background-color;
  padding: 12px;
  width: 42px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.fm-helmet-lock-status-item-text {
  font-size: 14px;
  line-height: 22px;
  margin: 7px 0 5px 0;
}

/* ------ Tabs ------ */

.fm-helmet-lock-tabs {
  display: flex;
  overflow-x: scroll;
}

.fm-helmet-lock-tab {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
  margin: 12px 0 10px 0;
  opacity: 0.4;
  cursor: pointer;
  white-space: nowrap;
}

.fm-helmet-lock-tabs-loading {
  height: 2px;
}

.fm-helmet-lock-tab-active {
  opacity: 1.0;
}

.fm-helmet-lock-tab-content {
  padding: 7px 15px 50px 15px;
  min-height: 500px;
}

.fm-helmet-lock-tab-content-empty {
  text-align: center;
  font-size: 14px;
  color: #999;
  margin: 50px;
}

.fm-helmet-lock-tab-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.fm-helmet-lock-tab-row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-helmet-lock-tab-row-title {
  font-size: 15px;
  padding: 5px 10px 5px 0;
  line-height: 20px;
}

.fm-helmet-lock-tab-row-detail {
  color: #999;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-helmet-lock-tab-photo-img {
  width: 100%;
  max-width: 500px;
  border-radius: 3px;
}

.fm-helmet-lock-tab-photo-empty {
  text-align: center;
  font-size: 14px;
  color: #999;
  margin: 50px;
}

.fm-helmet-lock-tab-row-tag {
  height: 24px;
  padding: 0;
  width: 105px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}
