.fm-vehicle-locator-map {
  height: 40vh;
}

.fm-vehicle-locator-header-row {
  margin: 20px 15px;
}

.fm-vehicle-locator-header-row-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-vehicle-locator-header-row-date {
  text-align: right;
  color: #9c9b9d;
  font-size: 13px;
  line-height: 22px;
}

.fm-vehicle-locator-header-row-value {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 2px;
}

.fm-vehicle-locator-header-row-label {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}

.fm-vehicle-locator-header-row-dot {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 0 7px 2px 0;
  vertical-align: middle;
}

.fm-vehicle-locator-key-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 25px 15px 10px 15px;
}

.fm-vehicle-locator-parking-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 25px 15px 5px 15px;
}

.fm-vehicle-locator-parking-container {
  padding: 15px;
  width: 100%;
}

.fm-vehicle-locator-parking-photo {
  max-width: 100%;
  border-radius: 3px;
  margin-bottom: 20px;
}

.fm-vehicle-locator-key-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
}

.fm-vehicle-locator-key-row-image {
  height: 25px;
  width: 25px;
}

.fm-vehicle-locator-key-row-label {
  font-size: 15px;
  line-height: 25px;
  margin-left: 10px;
}

.fm-vehicle-locator-timeline-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 25px 15px 10px 15px;
}

.fm-vehicle-locator-timeline-item-image {
  height: 25px;
  width: 25px;
}

.fm-vehicle-locator-timeline-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
}

.fm-vehicle-locator-timeline-item-title {
  font-size: 15px;
  line-height: 25px;
}

.fm-vehicle-locator-timeline-item-date {
  text-align: right;
  color: #9c9b9d;
  font-size: 13px;
  line-height: 22px;
}

.fm-vehicle-locator-timeline-item-details {
  margin-left: 10px;
}
