.fm-inspection-view-header {
  padding: 15px 15px 0 15px;
}

.fm-inspection-view-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.fm-inspection-view-result {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 15px;
  border-radius: 3px;
  display: inline-block;
  background: #e84118;
  letter-spacing: 0.5px;
  width: 100px;
  text-align: center;
}

.fm-inspection-view-result-pass {
  background: #4cd137;
}

.fm-inspection-view-completed {
  font-size: 14px;
  color: $light-text-color;
}

.fm-inspection-view-notes {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
}

.fm-inspection-view-user {
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0;
  font-weight: 600;
}

.fm-inspection-view-item {
  padding: 0 15px;
  margin: 25px 0;
}

.fm-inspection-view-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fm-inspection-view-item-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 0;
}

.fm-inspection-view-item-grade {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 3px;
  display: inline-block;
  background: #e84118;
}

.fm-inspection-view-item-grade-pass {
  background: #4cd137;
}

.fm-inspection-view-item-description {
  font-size: 14px;
  margin-top: 9px;
  padding: 1px;
  line-height: 20px;
}

.fm-inspection-view-item-directive {
  font-size: 14px;
  margin-top: 3px;
  padding: 1px;
  line-height: 20px;
  font-style: italic;
  color: $light-text-color;
}

.fm-inspection-view-item-photo {
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  max-width: 500px;
}
