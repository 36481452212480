.fm-user-access-event-uuid {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0;
  border-radius: 3px;
  flex: 0 0 55px;
  text-align: center;
  line-height: 16px;
  text-transform: uppercase;
  background: #333;
}

.fm-user-access-event {
  display: flex;
  margin: 20px;
  gap: 10px;
  align-items: flex-start;
}

.fm-user-access-event-created {
  font-size: 15px;
  text-align: right;
  margin-left: 15px;
}

.fm-user-access-event-detail {
  color: $light-text-color;
  font-size: 14px;
  flex: 1;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
