.fm-instruction-group-container {
  overflow-x: scroll;
  padding-bottom: 30px;
  position: relative;
}

.fm-instruction-group-items {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.fm-instruction-group-instruction {
  margin: 15px;
  width: fit-content;
  block-size: fit-content;
}

.fm-instruction-group-instruction-header {
  margin: 0 0 10px 0;
  position: sticky;
  left: 15px;
  display: inline-block;
}

.fm-instruction-group-instruction-name {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  vertical-align: top;
  user-select: all;
}

.fm-instruction-group-instruction-lang {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  color: $light-text-color;
  font-size: 14px;
  margin-left: 12px;
  line-height: 30px;
  vertical-align: top;
  user-select: all;
}

.fm-instruction-group-instruction-id {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  color: $light-text-color;
  font-size: 14px;
  margin-left: 10px;
  line-height: 30px;
  vertical-align: top;
  user-select: all;
}

.fm-instruction-group-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 300px;
  width: 300px;
  border: 1px solid $border-color;
  border-radius: 3px;
  padding: 15px;
}

.fm-instruction-group-item-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.fm-instruction-group-item-photo {
  height: 50px;
  width: 50px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  flex: 0 0 50px;
}

.fm-instruction-group-item-header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fm-instruction-group-item-id {
  text-transform: uppercase;
  font-weight: 600;
  color: $light-text-color;
  font-size: 14px;
  margin-left: 10px;
  user-select: all;
  line-height: 18px;
  height: 18px;
}

.fm-instruction-group-item-edit {
  height: 32px;
  width: 32px;
  background: none;
  border: none;
  font-size: 14px;
  color: $light-text-color;
  text-align: right;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.fm-instruction-group-item-edit .fa {
  pointer-events: none;
}

.fm-instruction-group-item-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0 5px 0;
}

.fm-instruction-group-item-description {
  font-size: 14px;
  line-height: 20px;
}

.fm-instruction-group-item-input {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 10px 0 5px 0;
  padding: 0;
  height: 22px;
  display: block;
  border-radius: 0;
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  background-color: $light-background-color;
}

.fm-instruction-group-item-textarea {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 5px 0;
  padding: 0;
  height: 80px;
  display: block;
  border: none;
  border-radius: 0;
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  -webkit-appearance: none;
  background-color: $light-background-color;
}
