
.fm-task-new-box {
  width: 100vw;
  max-width: 100%;
  height: 100vw;
  min-height: 325px;
  max-height: 400px;
  background: #222222;
}

.fm-task-new-selected {
  background: #222222;
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  min-height: 64px;
}

.fm-task-new-note {
  line-height: 38px;
  color: #ccc;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  padding: 5px;
}

.fm-task-new-selected-vehicle {
  background: #f8f7f9;
  margin: 7px 5px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  height: 34px;
}

.fm-task-new-selected-vehicle-status {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 6px;
}

.fm-task-new-selected-vehicle-rego {
  font-size: 16px;
  font-weight: 600;
}

.fm-task-new-options {
  padding: 13px;
}

.fm-task-new-map {
  height: 100%;
}

.fm-task-new-mode {
  padding: 13px;
  background: #222222;
}

.fm-task-new-mode-select {
  border-radius: 3px;
  font-size: 14px;
  padding: 8px 15px;
  background-color: #333;
  color: #fff;
  font-weight: 600;
}





.fm-task-new-search {
  padding: 5px 13px 13px 13px;
}

.fm-task-new-search .fm-input-container {
  margin: 0;
}

.fm-task-new-search-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}

.fm-task-new-search-result-registration {
  background: #000;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 3px 8px;
  border-radius: 3px;
  line-height: 25px;
}

.fm-task-new-search-result-status {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 3px;
}

.fm-task-new-auto {
  margin: 10px 0 0 0;
}

.fm-task-new-auto-item {
  margin: 0 10px 10px 0;
  border-radius: 3px;
  background-color: $light-background-color;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 8px;
  cursor: pointer;
  display: inline-block;
}

.fm-task-new-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: #ff206e;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 26px 0 13px 0;
  cursor: pointer;
}

.fm-task-new-options .fm-input {
  margin-bottom: 15px;
}

.fm-task-new-options-group {
  display: flex;
  flex-direction: row;
}

.fm-task-new-options-group .fm-input + .fm-input {
  margin-left: 15px;
}

@media (max-width: $breakpoint-md) {
  .fm-task-new-options-group {
    flex-direction: column;
  }
  .fm-task-new-options-group .fm-input + .fm-input {
    margin-left: 0;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-task-new-mode {
    background: $light-background-color-dark;
  }
  .fm-task-new-mode-select {
    background: $background-color-dark;
  }
  .fm-task-new-search .fm-input {
    background: $background-color-dark;
  }
  .fm-task-new-selected-vehicle {
    background: $background-color-dark;
  }
  .fm-task-new-auto-item {
    background: $light-background-color-dark;
    color: $text-color-dark;
  }
}
