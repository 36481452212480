
.fm-support-activity-options-go {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 0;
  font-size: 16px;
  color: #222222;
  border-radius: 5px;
  padding: 10px 15px;
  transition: ease all 0.1s;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  cursor: pointer;
  margin: 0 6px;
}

.fm-support-call-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 10px 0 12px 0;
  padding: 2px 15px;
  white-space: nowrap;
}

.fm-support-call-status-option {
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 10px;
  transition: all .1s ease;
  flex: 1;
  color: $text-color;
  background: $light-background-color;
}

.fm-support-call-status-option-active {
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 10px;
  transition: all .1s ease;
  flex: 1;
  background: $primary;
  color: #fff;
}

.fm-support-call-status-option:disabled, .fm-support-call-status-option-active:disabled {
  opacity: 0.7;
}

.fm-support-call-status-options {
  display: flex;
  padding: 0 15px;
  gap: 15px;
  margin-bottom: 30px;
}

.fm-support-call-user {
  display: flex;
  margin: 15px 15px 25px 15px;
  align-items: center;
}

.fm-support-call-user-name {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.fm-support-call-user-accepting {
  font-size: 14px;
  line-height: 20px;
  margin: 0 8px;
  color: $light-text-color;
}

.fm-support-call-user-status {
  border-radius: 7px;
  height: 14px;
  width: 14px;
  margin-top: 2px;
  margin-right: 10px;
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-support-call-status-option {
    background: $light-background-color-dark;
    color: $text-color-dark;
  }
}
