.fm-report-template-content {
  display: flex;
  padding: 7px 7px 50px 7px;
  flex-direction: column;
}

.fm-report-template-card {
  flex: 1;
  background: $light-background-color;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 7px;
  height: 100px;
  align-items: center;
}

.fm-report-template-card-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
}

.fm-report-template-card-icon {
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  line-height: 80px;
  text-align: center;
  font-size: 20px;
  color: $border-color-dark;
}

.fm-report-template-card-title {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  padding: 10px 0;
}

.fm-report-template-card-description {
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  color: $border-color-dark;
  margin-bottom: 12px;
  font-style: italic;
}

.fm-report-template-card-email {
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  color: $border-color-dark;
  margin-bottom: 14px;
  white-space: pre-wrap;

}

@media (prefers-color-scheme: dark) {
  .fm-report-template-card {
    background-color: $light-background-color-dark;
  }
  .fm-report-template-card-description, .fm-report-template-card-email {
    color: $light-text-color;
  }
}
