.fm-subscription-user-content {
  margin-top: 12px;
}

.fm-subscription-user-payments-container {
  padding: 7px 15px 50px;
}

.fm-subscription-user-payments-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
  margin: 12px 0 10px;
  cursor: pointer;
  white-space: nowrap;
}

.fm-subscription-user-row-terms {
  padding: 0 0 10px 0;
  line-height: 20px;
  font-size: 14px;
  color: $light-text-color;
}

.fm-subscription-user-row-key {
  font-size: 15px;
}

.fm-subscription-user-row-value {
  font-size: 15px;
  color: $light-text-color;
}

.fm-subscription-user-status {
  height: 24px;
  padding: 0;
  width: 105px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}

.fm-subscription-user-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 0;
}

.fm-subscription-user-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px 20px 15px;
}

.fm-user-tab-row-pointer {
  cursor: pointer;
}
