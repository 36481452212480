.fm-nest-map {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-nest-map-source {
  flex: 1;
  position: relative;
  transition: ease all 0.2s;
}

.fm-nest-map-source-search {
  height: 0;
  flex: 0;
  overflow: hidden;
}

.fm-nest-map-bar {
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid $border-color;
  padding: 13px 13px 11px 13px;
  background: #fff;
  z-index: 9999;
  flex: 0 0 68px;
}

.fm-nest-map-bar .fm-input-container {
  margin: 0;
  flex: 1;
}

.fm-nest-map-bar .fm-input {
  height: 44px;
  border-radius: 3px;
}

.fm-nest-map-bar-filter {
  height: 44px;
  background: $light-background-color;
  width: 44px;
  margin: 0 0 0 12px;
  border-radius: 3px;
  text-align: center;
  line-height: 44px;
  font-size: 20px;
  color: $text-color;
}

.fm-nest-map-search {
  padding: 0 14px;
  overflow: hidden;
  overflow-y: scroll;
  transition: ease all 0.2s;
}

.fm-nest-map-search-active {
  padding: 2px 14px 225px 14px;
}

.fm-nest-map-results-empty {
  color: #999;
  font-size: 14px;
  text-align: center;
  line-height: 150px;
}

.fm-nest-map-filters {
  height: 40px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  animation: fm-show-filters 0.5s ease;
  animation-fill-mode: forwards;
}

@keyframes fm-show-filters {
  0% {
    height: 0;
  }
  100% {
    bottom: 40px;
  }
}

.fm-nest-map-filters-statuses {
  padding: 0 8px 5px 8px;
  overflow-x: scroll;
  width: 100%;
  height: 37px;
  white-space: nowrap;
}

.fm-nest-map-filters-status {
  display: inline-block;
  color: #fff;
  margin: 5px;
  border-radius: 2px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  padding: 3px 6px;
}

.fm-nest-map-filters-selects {
  display: flex;
}

.fm-nest-map-filters .fm-input-container {
  margin: 5px 13px;
  flex: 1;
}

.fm-nest-map-filters .fm-input-container + .fm-input-container {
  margin: 5px 13px 5px 0;
}

.fm-nest-map-filters .fm-input-select {
  border-radius: 3px;
  font-size: 14px;
  padding: 8px 15px;
}

.fm-nest-map-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #E8E7EA;
}

.fm-nest-map-result-address {
  line-height: 22px;
}

.fm-nest-map-result-status {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 15px;
}
