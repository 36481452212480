.fm-nest-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
  padding: 5px 0;
}

.fm-nest-top-address {
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-nest-top-capacity {
  font-size: 13px;
  font-weight: 600;
  color: #666;
  background: #eee;
  padding: 2px 0;
  border-radius: 3px;
  line-height: 20px;
  width: 80px;
  flex: 0 0 80px;
  text-align: center;
  margin-left: 15px;
}
