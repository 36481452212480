.fm-zone-new {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-zone-new-main {
  flex: 1 1;
  display: flex;
}

.fm-zone-new-map {
  flex: 1 1;
  position: relative;
}

.fm-zone-new-details-content {
  padding: 0 15px 30px 15px;
}

.fm-zone-new-details-input {
  margin: 20px 0 25px 0;
}
