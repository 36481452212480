
/* ------ Tabs ------ */

.fm-user-tabs {
  display: flex;
  overflow-x: scroll;
}

.fm-user-tab {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 2px 15px;
  margin: 12px 0 10px 0;
  opacity: 0.4;
  cursor: pointer;
  white-space: nowrap;
}

.fm-user-tabs-loading {
  height: 2px;
}

.fm-user-tab-active {
  opacity: 1.0;
}

.fm-user-tab-content {
  padding: 7px 15px 50px 15px;
  min-height: 500px;
}

.fm-user-tab-content-empty {
  text-align: center;
  font-size: 14px;
  color: #999;
  margin: 50px;
}

.fm-user-tab-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.fm-user-tab-row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fm-user-tab-row-title {
  font-size: 15px;
  padding: 5px 10px 5px 0;
  line-height: 20px;
}

.fm-user-tab-row-detail {
  color: #999;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-user-tab-row-tag {
  height: 24px;
  padding: 0;
  width: 105px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}
