.fm-region-service-area-edit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-region-service-area-edit-main {
  flex: 1 1;
  display: flex;
}

.fm-region-service-area-edit-map {
  flex: 1 1;
  position: relative;
}
