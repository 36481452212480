.fm-navigation {
  width: 100%;
  height: 61px;
  flex: 0 0 61px;
}

.fm-navigation-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 61px;
  border-bottom: 1px solid #E8E7EA;
  background: #fff;
  z-index: 999;
}

.fm-navigation-loading {
  position: fixed;
  top: 61px;
  right: 0;
  left: 0;
  height: 2px;
  z-index: 9999;
}

.fm-navigation-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fm-navigation-left, .fm-navigation-right {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.fm-navigation-right {
  flex: 0 0 auto;
}

.fm-navigation-title {
  font-weight: 600;
  font-size: 18px;
  margin: 15px 0 15px 15px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-navigation-subtitle {
  font-size: 13px;
  color: $light-text-color;
  font-weight: 500;
  line-height: 30px;
  margin: 15px 0 15px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-navigation-actions {
  display: flex;
  flex-direction: row;
}

.fm-navigation-action {
  display: block;
  background: #fff;
  font-size: 20px;
  color: #222222;
  width: 50px;
  height: 50px;
  line-height: 26px;
  padding: 12px;
  text-align: center;
  margin: 5px 3px 5px 0;
  border: none;
  cursor: pointer;
}

.fm-navigation-back {
  background: #fff;
  font-size: 20px;
  color: #222222;
  width: 50px;
  height: 50px;
  line-height: 26px;
  padding: 12px;
  text-align: center;
  margin: 5px 3px 5px 0;
  border: none;
}

.fm-navigation-back + .fm-navigation-title {
  margin: 15px 0 15px 0;
}

@media (min-width: $breakpoint-lg) {
  .fm-navigation-bar, .fm-navigation-loading {
    left: 250px;
  }
}

@media (prefers-color-scheme: dark) {
  .fm-navigation-bar {
    border-bottom-color: $border-color-dark;
    background: $background-color-dark;
  }
  .fm-navigation-action, .fm-navigation-back {
    background: $background-color-dark;
    color: $text-color-dark;
  }
}
