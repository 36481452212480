.fm-user-area {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-user-area-map {
  flex: 1 1;
  position: relative;
}

.fm-user-area-controls {
  width: 100%;
  height: 47px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #E8E7EA;
  padding: 8px 13px 6px 13px;
  background: #fff;
  z-index: 9999;
  flex: 0 0 47px;
  overflow-x: scroll;
  white-space: nowrap;
}

.fm-user-area-date {
  display: block;
  text-align: center;
  width: 100%;
  line-height: 32px;
  font-size: 14px;
  font-weight: 600;
}
