.fm-subscription-list-search .fm-input-container {
  margin: 15px;
}

.fm-subscription-list-items {
  margin-bottom: 50px;
}

.fm-subscription-list-item {
  margin: 14px;
  display: block;
  padding: 10px 14px 14px 14px;
  border: 1px solid $border-color;
  border-radius: 3px;
}

.fm-subscription-list-item-title {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}

.fm-subscription-list-item-details {
  display: flex;
  gap: 10px;
  margin: 4px 0 14px 0;
  align-items: center;
}

.fm-subscription-list-item-details-label {
  color: #9c9b9d;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
}

.fm-subscription-list-item-details-spacer {
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background: $light-text-color;
}

.fm-subscription-list-item-plans {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fm-subscription-list-item-plan {
  background: $light-background-color;
  border-radius: 3px;
  padding: 10px;
}

.fm-subscription-list-item-plan-name {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.fm-subscription-list-item-plan-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.fm-subscription-list-item-plan-label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin-top: 5px;
}

@media (max-width: $breakpoint-sm) {
  .fm-subscription-list-item-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .fm-subscription-list-item-details-spacer {
    display: none;
  }
}

/* ------ Dark Mode ------ */

@media (prefers-color-scheme: dark) {
  .fm-subscription-list-item-plan {
    background-color: $light-background-color-dark;
  }
  .fm-subscription-list-item {
    border-color: $border-color-dark;
  }
}
