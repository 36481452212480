.fm-zone-edit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fm-zone-edit-main {
  flex: 1 1;
  display: flex;
}

.fm-zone-edit-map {
  flex: 1 1;
  position: relative;
}
