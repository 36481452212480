.fm-staff {

}

.fm-staff-row-light {
  color: $light-text-color;
}


@media (max-width: $breakpoint-lg) {
  .fm-staff-row-no-mobile {
    display: none;
  }
}


.fm-staff-map {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fm-staff-map-container {
  flex: 1;
}
