.fm-report-interference-content {
  padding: 14px 14px 50px 14px;
}

.fm-report-interference-content .fm-input {
  margin: 14px 0;
}

.fm-report-interference-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 2px;
}

.fm-report-interference-info {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 2px;
}

.fm-report-interference-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 28px 0;
  cursor: pointer;
}
