.fm-voucher-list-search .fm-input-container {
  margin: 15px;
}

.fm-voucher-list-items {
  margin-bottom: 50px;
}

.fm-voucher-list-item {
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  margin: 20px 20px 25px 20px;
}

.fm-voucher-list-item-status {
  flex: 0 0 14px;
}

.fm-voucher-list-item-status-dot {
  background: #4cd137;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}

.fm-voucher-list-item-status-dot-inactive {
  background: #e84118;
}

.fm-voucher-list-item-details {

}

.fm-voucher-list-item-main {
  flex: 1;
  min-width: 0;
}

.fm-voucher-list-item-code {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}

.fm-voucher-list-item-notes {
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin: 4px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fm-voucher-list-item-redemptions {
  display: block;
  text-align: right;
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
}

.fm-voucher-list-item-expires {
  display: block;
  text-align: right;
  color: $light-text-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  margin-top: 4px;
}

.fm-voucher-list-empty {
  color: #999;
  font-size: 14px;
  text-align: center;
  line-height: 200px;
}
