
/* ------ Structure ------ */

.fm-trip-search {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fm-trip-search-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

/* ------ Map ------ */

.fm-trip-search-map {
  flex: 1;
}

/* ------ Details ------ */

.fm-trip-search-details {
  flex: 1;
  overflow-y: scroll;
}

/* ------ Form ------ */

.fm-trip-search-form {
  padding: 0 15px;
}

.fm-trip-search-input-row {
  display: flex;
  flex-direction: row;
}

.fm-trip-search-input, .fm-trip-search-input-row  {
  margin: 20px 0 25px 0;
  flex: 1;
}

.fm-trip-search-input-row .fm-trip-search-input {
  margin: 0;
}

.fm-trip-search-input-row .fm-trip-search-input:nth-child(2) {
  margin-left: 20px;
}

.fm-trip-search-button {
  border: 0;
  font-size: 16px;
  color: #FFFFFF;
  background: $primary;
  font-weight: 600;
  border-radius: 3px;
  padding: 10px 12px;
  -webkit-transition: ease all 0.1s;
  transition: ease all 0.1s;
  display: block;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Lato, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 21px;
  margin: 13px 0;
  cursor: pointer;
}

/* ------ Results ------ */

.fm-trip-search-results {
  padding: 15px 20px;
}

.fm-trip-search-results-count {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.fm-trip-search-result {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.fm-trip-search-result-color {
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.fm-trip-search-result-details {
  margin-left: 15px;
}

.fm-trip-search-result-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.fm-trip-search-result-description {
  font-size: 14px;
  color: $light-text-color;
  line-height: 24px;
}

/* ------ Media Queries ------ */

@media (max-width: $breakpoint-xl) {
  .fm-trip-search-input-row {
    display: block;
  }
  .fm-trip-search-input-row  {
    margin: 0;
  }
  .fm-trip-search-input-row .fm-trip-search-input {
    margin: 20px 0 25px 0;
  }
  .fm-trip-search-input-row .fm-trip-search-input:nth-child(2) {
    margin-left: 0;
  }
}

@media (max-width: $breakpoint-sm) {
  .fm-trip-search-content {
    flex-direction: column-reverse;
    overflow-y: scroll;
  }
  .fm-trip-search-map {
    flex: 0 0 300px;
    height: 300px;
  }
  .fm-trip-search-details {
    overflow-y: visible;
  }
}
