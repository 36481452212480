.fm-replace-content {
  padding: 0 15px 30px 15px;
}

.fm-replace-input {
  margin: 20px 0 25px 0;
}

.fm-replace-body {
  margin-top: 15px;
  line-height: 22px;
  font-size: 14px;
}

.fm-replace-body-important {
  font-weight: 600;
  color: red;
}


.fm-replace-slider {
  background-color: #f8f7f9;
  border-radius: 3px;
  height: 40px;
  overflow: hidden;
  padding: 2px;
  position: relative;
}

.fm-replace-slider-background {
  background: pink;
  border-radius: 2px;
  bottom: 2px;
  position: absolute;
  top: 2px;
  transition: all .3s ease;
}

.fm-replace-slider-spare .fm-replace-slider-background {
  background-color: #f0932b;
  left: 2px;
  right: 50%;
}

.fm-replace-slider-existing .fm-replace-slider-background {
  background-color: #f0932b;
  left: 50%;
  right: 2px;
}

.fm-replace-slider-options {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
  position: relative;
  z-index: 9;
}

.fm-replace-slider-option {
  background: none;
  color: #000;
  cursor: pointer;
  flex: 1 1;
  font-size: 14px;
  font-weight: 600;
  height: 36px;
  letter-spacing: .5px;
  transition: all .2s ease;
}

.fm-replace-slider-spare .fm-replace-slider-option-spare, .fm-replace-slider-existing .fm-replace-slider-option-existing {
  color: #fff;
}

.fm-replace-slider-title {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 20px;
}
