.fm-selfies {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fm-selfies-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  justify-content: center;
}

.fm-selfies-empty {
  color: #999;
  font-size: 14px;
}

.fm-selfies-image {
  width: 100%;
  flex: 1;
  max-width: 500px;
  background-size: contain;
  background-position: 50%;
  background-color: #eee;
  background-repeat: no-repeat;
  border-radius: 3px;
}

.fm-selfies-image-approved {
  animation: fm-selfies-approved 0.5s ease;
  animation-fill-mode: forwards;
}

.fm-selfies-image-invalid {
  animation: fm-selfies-invalid 0.5s ease;
  animation-fill-mode: forwards;
}

@keyframes fm-selfies-approved {
  0% {
    background-color: #eee;
  }

  10% {
    background-color: #2ecc71;
  }

  100% {
    background-color: #eee;
  }
}

@keyframes fm-selfies-invalid {
  0% {
    background-color: #eee;
  }

  10% {
    background-color: #e74c3c;
  }

  100% {
    background-color: #eee;
  }
}

.fm-selfies-controls {
  max-width: 500px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

button.fm-selfies-controls-invalid {
  flex: 1;
  border-radius: 3px;
  background: #e74c3c;
  color: #fff;
  font-weight: 600;
  line-height: 35px;
  font-size: 15px;
  cursor: pointer;
  height: 35px;
  padding: 0;
}

a.fm-selfies-controls-ride {
  flex: 1;
  border-radius: 3px;
  background: #ecf0f1;
  line-height: 35px;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
  text-align: center;
  height: 35px;
  padding: 0;
}

button.fm-selfies-controls-approved {
  flex: 1;
  border-radius: 3px;
  background: #2ecc71;
  color: #fff;
  font-weight: 600;
  line-height: 35px;
  font-size: 15px;
  cursor: pointer;
  height: 35px;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  .fm-selfies-image, a.fm-selfies-controls-ride {
    background-color: $light-background-color-dark;
  }
}
